import { FormattedNetworkMerchantSearchResult, NetworkMerchantSearchResult } from './types';

// react complains about props that start with an uppercase
// caused by passing results to SearchUI
export const objectKeysToLowerCase = (result: NetworkMerchantSearchResult): FormattedNetworkMerchantSearchResult => {
  const newObject: any = {};

  newObject.id = result.id;
  newObject.name = result.name;
  newObject.disabled = result.disabled;
  newObject.networkid = result.networkID;
  newObject.networkname = result.networkName;
  newObject.resourceid = result.id;
  newObject.title = '';

  return newObject;
};

export const generateCommissionAmount = (commissionRate: number, saleAmount: number) => {
  if (!commissionRate || !saleAmount) return 0;
  return Number(((commissionRate / 100) * saleAmount).toFixed(2));
};
