import React, { useState } from 'react';
import TruncatedTableCell from 'components/CommissionSearch/TruncatedTableCell';
import ChangeIcon from 'components/icons/ChangeIcon';
import CheckIcon from 'components/icons/CheckIcon';
import { CommissionRateQueueItem, CommissionRateQueueStatus } from './types';
import { Network } from 'redux/store/network/types';
interface CommissionRateQueueTableRowProps {
  item: CommissionRateQueueItem;
  networkName: string;
  status: CommissionRateQueueStatus;
  onApprove: (item: CommissionRateQueueItem) => void;
  onRevise: (item: CommissionRateQueueItem) => void;
}

const CommissionRateQueueTableRow: React.FC<CommissionRateQueueTableRowProps> = ({
  item,
  networkName,
  status,
  onApprove,
  onRevise,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedDisplayName, setEditedDisplayName] = useState(item.suggestedDisplayName);
  const [editedNote, setEditedNote] = useState(item.note);

  const handleConfirmEdit = () => {
    const updatedItem = { ...item, revisedDisplayName: editedDisplayName, note: editedNote };
    onRevise(updatedItem);
    setIsEditing(false);
  };

  const handleCancelEdit = () => {
    setEditedDisplayName(item.suggestedDisplayName);
    setIsEditing(false);
  };

  const handleApprove = () => {
    const updatedItem = { ...item, note: editedNote };
    onApprove(updatedItem);
  };

  const handleNoteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newNote = e.target?.value ?? '';
    setEditedNote(newNote);
  };

  const needsReview = item.suggestedDisplayName.toLowerCase().includes('needs review');
  const statusColor =
    item.status === 'PENDING' ? 'bg-orange-400' : item.status === 'APPROVED' ? 'bg-green-400' : 'bg-red-400';

  const isDisplayNameChanged = editedDisplayName !== item.suggestedDisplayName;

  return (
    <tr className={`text-center ${needsReview ? '!bg-red-100' : ''}`}>
      <td className="!py-6">{item.reviewItemId}</td>
      <td>
        <span className={`${statusColor} text-white text-sm font-medium me-2 p-2 rounded`}>{item.status}</span>
      </td>
      <td>{item.meta.networkMerchant.id}</td>
      <td className="whitespace-nowrap">{item.meta.networkMerchant.name || ''}</td>
      <td>{item.meta.merchant.score}</td>
      <td>{networkName || ''}</td>
      <td>{item.commissionRateId}</td>
      {item.status === 'PENDING' && <TruncatedTableCell>{item.meta.commissionRate.name}</TruncatedTableCell>}
      <td className="!whitespace-nowrap !text-left">
        {isEditing ? (
          <input
            type="text"
            value={editedDisplayName}
            onChange={e => setEditedDisplayName(e.target.value)}
            className="w-full p-1 border rounded"
          />
        ) : (
          item.suggestedDisplayName
        )}
      </td>
      {item.status !== 'PENDING' && (
        <td className="!whitespace-nowrap !text-left">{item.meta.commissionRate.displayName}</td>
      )}
      <TruncatedTableCell>{item.proposedBy}</TruncatedTableCell>
      <td className="whitespace-nowrap">{new Date(item.createdDate).toLocaleString()}</td>
      <td className="!whitespace-nowrap">{item.reviewer}</td>

      {status === 'PENDING' ? (
        <td>
          <input
            type="text"
            value={editedNote}
            onChange={handleNoteChange}
            className="w-full p-1 border rounded min-w-[150px]"
          />
        </td>
      ) : (
        <TruncatedTableCell>{item.note}</TruncatedTableCell>
      )}

      {status === 'PENDING' ? (
        <td>
          {isEditing ? (
            <div className="flex justify-center gap-2">
              <button
                onClick={handleConfirmEdit}
                disabled={!isDisplayNameChanged}
                className={`text-sm rounded-md px-3 py-2 ${
                  isDisplayNameChanged
                    ? 'bg-green-500 text-white hover:bg-green-600'
                    : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                }`}
              >
                Confirm
              </button>
              <button
                onClick={handleCancelEdit}
                className="text-sm bg-red-500 text-white hover:bg-red-600 rounded-md px-3 py-2"
              >
                Cancel
              </button>
            </div>
          ) : (
            <div className="flex justify-center gap-2">
              <button
                title="Approve"
                onClick={handleApprove}
                className="text-xs bg-green-300 hover:bg-green-500 hover:text-white rounded-full p-2"
              >
                <CheckIcon />
              </button>
              <button
                title="Edit"
                onClick={() => setIsEditing(true)}
                className="text-xs bg-violet-300 hover:bg-violet-500 hover:text-white rounded-full p-2"
              >
                <ChangeIcon />
              </button>
            </div>
          )}
        </td>
      ) : (
        <td className="!whitespace-nowrap">{new Date(item.meta.commissionRate.modifiedDate).toLocaleString()}</td>
      )}
    </tr>
  );
};

export default CommissionRateQueueTableRow;
