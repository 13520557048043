import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Dimmer, Loader, Segment, Dropdown, Checkbox } from 'semantic-ui-react';
import {
  getCommissionRateQueue,
  approveCommissionRate,
  reviseCommissionRate,
} from '../../actions/commissionRateQueue/commissionRateQueue';
import { updateNetworkMerchantCommission } from '../../actions/merchantAdmin/networkMerchant';
import { getNetworks } from 'actions/networkMerchantOnboarding/networkMerchantOnboarding';
import CommissionRateQueueTable from '../../components/CommissionRateQueue/CommissionRateQueueTable';
import { CommissionRateQueueItem, CommissionRateQueueStatus } from '../../components/CommissionRateQueue/types';
import { Network } from 'redux/store/network/types';

interface CommissionRateQueueProps {
  getCommissionRateQueue: (status: CommissionRateQueueStatus) => Promise<CommissionRateQueueItem[]>;
  approveCommissionRate: (item: CommissionRateQueueItem) => Promise<any>;
  reviseCommissionRate: (item: CommissionRateQueueItem) => Promise<any>;
  getNetworks: () => Promise<Network[]>;
}

const statusOptions = [
  { key: 'PENDING', text: 'Pending', value: 'PENDING' },
  { key: 'APPROVED', text: 'Approved', value: 'APPROVED' },
  { key: 'REVISED', text: 'Revised', value: 'REVISED' },
];

const CommissionRateQueue: React.FC<CommissionRateQueueProps> = ({
  getCommissionRateQueue,
  approveCommissionRate,
  reviseCommissionRate,
  getNetworks,
}) => {
  const urlParams = new URLSearchParams(window.location.search);
  const history = useHistory();
  const initialStatus: CommissionRateQueueStatus = (urlParams.get('status') as CommissionRateQueueStatus) || 'PENDING';

  const [queueData, setQueueData] = useState<CommissionRateQueueItem[]>([]);
  const [networks, setNetworks] = useState<Network[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [activeStatusFilter, setActiveStatusFilter] = useState<CommissionRateQueueStatus>(initialStatus);
  const [showNeedsReviewAtTop, setShowNeedsReviewAtTop] = useState(false);

  const fetchCommissionRateQueue = async (status: CommissionRateQueueStatus) => {
    try {
      setIsLoading(true);
      const response = await getCommissionRateQueue(status as CommissionRateQueueStatus);
      setQueueData(response);
    } catch (err) {
      setError(err as Error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchNetworks = async () => {
    try {
      const res = await getNetworks();
      setNetworks(res);
    } catch (err) {
      toast.error('There was an error while fetching networks, please try again later.');
      console.error('Error fetching networks:', err);
    }
  };

  const getUserEmail = () => {
    const userEmail = localStorage.getItem('userEmail');
    if (!userEmail) {
      toast.error('User email not found in localStorage');
      return null;
    }
    return userEmail;
  };

  const handleApprove = async (rateData: CommissionRateQueueItem) => {
    const userEmail = getUserEmail();
    if (!userEmail) return;

    try {
      await approveCommissionRate({ ...rateData, reviewer: userEmail });
      await fetchCommissionRateQueue(activeStatusFilter);
    } catch (err) {
      console.error('Error approving commission rate:', err);
    }
  };

  const handleRevise = async (rateData: CommissionRateQueueItem) => {
    const userEmail = getUserEmail();
    if (!userEmail) return;

    try {
      await reviseCommissionRate({ ...rateData, reviewer: userEmail });
      await fetchCommissionRateQueue(activeStatusFilter);
    } catch (err) {
      console.error('Error revising commission rate:', err);
    }
  };

  const handleToggleNeedsReview = () => {
    setShowNeedsReviewAtTop(!showNeedsReviewAtTop);
  };

  useEffect(() => {
    fetchNetworks();
    // only run once on first load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    urlParams.set('status', activeStatusFilter);
    history.push(`?${urlParams.toString()}`);

    const status = urlParams.get('status') || 'PENDING';
    fetchCommissionRateQueue(status as CommissionRateQueueStatus);
  }, [activeStatusFilter]);

  if (isLoading) {
    return (
      <Segment className="!border-0 !shadow-none mt-[50px]">
        <Dimmer active inverted>
          <Loader>Loading</Loader>
        </Dimmer>
      </Segment>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="max-w-[95vw]">
      <h1 className="font-title my-2 text-center">Commission Rate Queue</h1>
      <div className="flex justify-between mb-5">
        <Dropdown
          selection
          options={statusOptions}
          value={activeStatusFilter}
          onChange={(_, data) => setActiveStatusFilter(data.value as CommissionRateQueueStatus)}
          className="rounded-lg !shadow-md"
          icon={<i className="dropdown icon text-wildfire-orange" />}
        />
        <Checkbox
          label="Show 'Needs Review' at Top"
          checked={showNeedsReviewAtTop}
          onChange={handleToggleNeedsReview}
          className={`p-3 rounded-lg shadow-md ${showNeedsReviewAtTop ? 'bg-green-200' : 'bg-white'}`}
        />
      </div>
      <CommissionRateQueueTable
        networks={networks}
        data={queueData}
        status={activeStatusFilter}
        showNeedsReviewAtTop={showNeedsReviewAtTop}
        onApprove={handleApprove}
        onRevise={handleRevise}
      />
    </div>
  );
};

const mapDispatchToProps = {
  getNetworks,
  getCommissionRateQueue,
  approveCommissionRate,
  reviseCommissionRate,
  updateNetworkMerchantCommission,
};

export default connect(null, mapDispatchToProps)(CommissionRateQueue);
