import React from 'react';
import { TableHeaderCell } from 'semantic-ui-react';
import { CommissionRateQueueStatus } from './types';

interface CommissionRateQueueTableHeaderProps {
  status: CommissionRateQueueStatus;
}

const CommissionRateQueueTableHeader: React.FC<CommissionRateQueueTableHeaderProps> = ({ status }) => {
  return (
    <thead className="bg-gray-100 whitespace-nowrap">
      <tr>
        <TableHeaderCell className="text-sm !text-center p-2">Review Item ID</TableHeaderCell>
        <TableHeaderCell className="text-sm !text-center p-2">Status</TableHeaderCell>
        <TableHeaderCell className="text-sm !text-center p-2">Network Merchant ID</TableHeaderCell>
        <TableHeaderCell className="text-sm !text-center p-2">Network Merchant Name</TableHeaderCell>
        <TableHeaderCell className="text-sm !text-center p-2">Merchant Score</TableHeaderCell>
        <TableHeaderCell className="text-sm !text-center p-2">Affiliate Network</TableHeaderCell>
        <TableHeaderCell className="text-sm !text-center p-2">Network Merchant Commission ID</TableHeaderCell>
        {status === 'PENDING' && (
          <TableHeaderCell className="text-sm !text-center p-2">Network Merchant Commission Name</TableHeaderCell>
        )}
        <TableHeaderCell className="text-sm !text-center p-2">Suggested Display Name</TableHeaderCell>
        {status !== 'PENDING' && (
          <TableHeaderCell className="text-sm !text-center p-2">Updated Commission Name</TableHeaderCell>
        )}
        <TableHeaderCell className="text-sm !text-center p-2">Proposed By</TableHeaderCell>
        <TableHeaderCell className="text-sm !text-center p-2">Created Date</TableHeaderCell>
        <TableHeaderCell className="text-sm !text-center p-2">Reviewer</TableHeaderCell>
        <TableHeaderCell className="text-sm !text-center p-2">Note</TableHeaderCell>
        {status === 'PENDING' ? (
          <TableHeaderCell className="text-sm !text-center p-2">Actions</TableHeaderCell>
        ) : (
          <TableHeaderCell className="text-sm !text-center p-2">Reviewer Updated Date</TableHeaderCell>
        )}
      </tr>
    </thead>
  );
};

export default CommissionRateQueueTableHeader;
