import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, Loader, Pagination } from 'semantic-ui-react';
import {
  getBonusPaymentsList,
  addBonusPayment,
  updateBonusPayment,
  deleteBonusPayment,
} from 'actions/reconciles/reconciles';
import {
  IDeviceIDResponse,
  INetworkMerchantSearchResult,
  IPendingPayoutList,
  IPendingPayoutArgs,
  IPendingPayoutResponse,
  IAddBonusPaymentsData,
} from 'components/PendingPayout/types';
import { getNetworks } from 'actions/networkMerchantOnboarding/networkMerchantOnboarding';
import { getNetworkMerchantSearchResults } from 'actions/merchantAdmin';
import { getFilterOptions } from 'actions/salesDashboard/dashboardStats';
import { getDeviceIDByTrackingCode } from 'actions/reconciles/reconciles';
import PendingPayoutTable from 'components/PendingPayout/PendingPayoutTable';
import PendingPayoutForm from 'components/PendingPayout/PendingPayoutForm';
import { IApplicationItem } from 'components/PendingPayout/types';
import { Network } from 'redux/store/network/types';

interface IPendingPayoutProps {
  getBonusPaymentsList: (args: IPendingPayoutArgs) => Promise<IPendingPayoutResponse>;
  getNetworks: () => Promise<Network[]>;
  getNetworkMerchantSearchResults: (search: string, networkId: number) => Promise<INetworkMerchantSearchResult[]>;
  getFilterOptions: (category: string) => Promise<IApplicationItem[]>;
  getDeviceIDByTrackingCode: (args: {
    appId: string;
    trackingCode: string;
    eventDate: string;
  }) => Promise<IDeviceIDResponse>;
  addBonusPayment: (args: IAddBonusPaymentsData) => Promise<void>;
  updateBonusPayment: (args: { id: number; body: IAddBonusPaymentsData }) => Promise<void>;
  deleteBonusPayment: (id: number) => Promise<void>;
}

const PendingPayout: React.FC<IPendingPayoutProps> = ({
  getBonusPaymentsList,
  getNetworks,
  getNetworkMerchantSearchResults,
  getFilterOptions,
  getDeviceIDByTrackingCode,
  addBonusPayment,
  updateBonusPayment,
  deleteBonusPayment,
}) => {
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isListLoading, setIsListLoading] = useState(false);
  const [networks, setNetworks] = useState<Network[]>([]);
  const [pendingPayoutList, setPendingPayoutList] = useState<IPendingPayoutList[]>([]);
  const [applications, setApplications] = useState<IApplicationItem[]>([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const fetchPendingPayoutList = async () => {
    try {
      setIsListLoading(true);
      const response = await getBonusPaymentsList({
        pageNumber: activePage,
        status: 'PENDING',
      });
      setTotalPages(response.totalPages || 1);
      setPendingPayoutList(response.bonusPayments || []);
    } catch (error) {
      toast.error('Failed to retrieve transaction inquiries');
      console.error(error);
    } finally {
      setIsListLoading(false);
    }
  };

  const handlePaginationChange = (e: React.MouseEvent<HTMLAnchorElement>, { activePage }: any) => {
    setActivePage(activePage);
  };

  useEffect(() => {
    fetchPendingPayoutList();
  }, [activePage]);

  useEffect(() => {
    const fetchNetworks = async () => {
      const networks = await getNetworks();
      setNetworks(networks);
    };

    const fetchApplications = async () => {
      const applications = await getFilterOptions('application');
      setApplications(applications);
    };

    fetchNetworks();
    fetchApplications();
  }, []);
  return (
    <>
      <div className="w-full text-center">
        <h1 className="font-title">Transaction Inquiry Pending Payout List</h1>
        <div className="bg-white min-h-[200px] w-full rounded-md shadow-lg p-5 mt-8">
          <div className="flex justify-end">
            <Button className="orange-rounded-button !text-sm" onClick={() => setIsAddModalOpen(true)}>
              + Add a new transaction inquiry
            </Button>
          </div>
          <div className="max-w-[1350px] mx-auto my-5">
            <PendingPayoutTable
              pendingPayoutList={pendingPayoutList}
              networks={networks}
              applications={applications}
              getNetworkMerchantSearchResults={getNetworkMerchantSearchResults}
              getDeviceIDByTrackingCode={getDeviceIDByTrackingCode}
              updateBonusPayment={updateBonusPayment}
              fetchPendingPayoutList={fetchPendingPayoutList}
              deleteBonusPayment={deleteBonusPayment}
            />
          </div>
          <Pagination
            activePage={activePage}
            onPageChange={handlePaginationChange}
            totalPages={totalPages}
            data-testid="tx-inquiry-pagination"
          />
        </div>
      </div>
      {isAddModalOpen && (
        <PendingPayoutForm
          submitMode="add"
          networks={networks}
          applications={applications}
          isOpen={isAddModalOpen}
          getNetworkMerchantSearchResults={getNetworkMerchantSearchResults}
          getDeviceIDByTrackingCode={getDeviceIDByTrackingCode}
          fetchPendingPayoutList={fetchPendingPayoutList}
          onSubmit={addBonusPayment}
          setIsOpen={setIsAddModalOpen}
        />
      )}
    </>
  );
};

const mapActionsToProp = {
  getBonusPaymentsList,
  getNetworks,
  getNetworkMerchantSearchResults,
  getFilterOptions,
  getDeviceIDByTrackingCode,
  addBonusPayment,
  updateBonusPayment,
  deleteBonusPayment,
};

export default connect(null, mapActionsToProp)(PendingPayout);
