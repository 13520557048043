import React from 'react';
import { Link } from 'react-router-dom';
import { Table, TableBody, Button } from 'semantic-ui-react';
import KeywordTargetQueueTableHeaders from './KeywordTargetQueueTableHeader';
import { CouponTargetAssignment, CouponTargetAssignmentStatus } from '../../pages/KeywordTargetQueue/types';

interface KeywordTargetQueueTableProps {
  couponTargetAssignments: CouponTargetAssignment[];
  activeStatusFilter: CouponTargetAssignmentStatus;
  isUpdatingCouponTargetAssignment: boolean;
  handleOnActionButtonClick: (id: number, status: 'BLOCKED' | 'IGNORED') => Promise<void>;
}

const KeywordTargetQueueTable = (props: KeywordTargetQueueTableProps) => {
  const { couponTargetAssignments, activeStatusFilter, isUpdatingCouponTargetAssignment, handleOnActionButtonClick } =
    props;

  const className = 'keyword-target-queue-table';
  const tableRows = couponTargetAssignments.map(({ ID, KeywordID, Keyword, Merchant }) => {
    return (
      <tr key={ID}>
        <td>
          <p>{ID}</p>
        </td>
        <td>
          <p>{Merchant}</p>
        </td>
        <td>
          <p>{Keyword}</p>
        </td>
        <td>
          <div className="flex justify-center">
            <Link to={`/keyword-targets/${KeywordID}`}>
              <Button className="!font-montserrat grey-rounded-button !text-sm">More info</Button>
            </Link>
          </div>
        </td>
        {activeStatusFilter === 'PENDING' && (
          <td>
            <div className="flex justify-center">
              <Button
                className="!font-montserrat grey-rounded-button !text-sm"
                disabled={isUpdatingCouponTargetAssignment}
                onClick={() => handleOnActionButtonClick(ID, 'BLOCKED')}
              >
                Block
              </Button>
              <Button
                className="!font-montserrat grey-rounded-button !text-sm"
                disabled={isUpdatingCouponTargetAssignment}
                onClick={() => handleOnActionButtonClick(ID, 'IGNORED')}
              >
                Ignore
              </Button>
            </div>
          </td>
        )}
      </tr>
    );
  });

  return (
    <div className="keyword-target-queue-table-container flex justify-center">
      <Table celled striped aria-label="keyword target queue table" className="font-montserrat">
        <KeywordTargetQueueTableHeaders activeStatusFilter={activeStatusFilter} />
        <TableBody>{tableRows}</TableBody>
      </Table>
    </div>
  );
};

export default KeywordTargetQueueTable;
