import React, { useMemo, useState } from 'react';
import { Button, Form, DropdownProps } from 'semantic-ui-react';
import { statusOption } from './constants';
import { TxInquiryFormState, TxInquiry } from './types';

const today = new Date();
const dateToday = new Intl.DateTimeFormat('fr-CA').format(today); // using fr-CA to get "YYYY/MM/DD" format

interface TxInquiryFiltersProps {
  txInquiryList: TxInquiry[];
  formState: TxInquiryFormState;
  setFormState: (formState: TxInquiryFormState) => void;
}

const TxInquiryFilters: React.FC<TxInquiryFiltersProps> = ({ txInquiryList, formState, setFormState }) => {
  const [formFieldState, setFormFieldState] = useState<TxInquiryFormState>(formState);

  const {
    status,
    payableApplicationId,
    modifiedAuthor,
    minCreatedDate,
    maxCreatedDate,
    minModifiedDate,
    maxModifiedDate,
  } = formFieldState;

  const modifiedAuthorOptions = useMemo(() => {
    const authors = Array.from(new Set(txInquiryList.map(txInquiry => txInquiry.modifiedAuthor)));
    return authors.map(author => ({
      key: author,
      text: author,
      value: author,
    }));
  }, [txInquiryList]);

  const applicationOptions = useMemo(() => {
    const uniqueApps = Array.from(
      new Map(
        txInquiryList.map(txInquiry => [
          txInquiry.payableApplicationId,
          { id: txInquiry.payableApplicationId, name: txInquiry.payableApplicationName },
        ]),
      ).values(),
    );

    return uniqueApps.map(app => ({
      key: app.id,
      text: `${app.id} - ${app.name}`,
      value: app.id,
    }));
  }, [txInquiryList]);

  const handleDropdownChange = (_: React.SyntheticEvent<HTMLElement, Event>, { value, name }: DropdownProps) => {
    setFormFieldState({ ...formFieldState, [name]: value });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Convert format for date fields from yyyy-mm-dd to yyyymmdd
    const newState: TxInquiryFormState = {
      ...formFieldState,
      minCreatedDate: minCreatedDate.replace(/-/g, ''),
      maxCreatedDate: maxCreatedDate.replace(/-/g, ''),
      minModifiedDate: minModifiedDate.replace(/-/g, ''),
      maxModifiedDate: maxModifiedDate.replace(/-/g, ''),
    };
    setFormState(newState);
  };

  const handleClear = () => {
    const resetFormState: TxInquiryFormState = {
      status: 'PENDING',
      payableApplicationId: 0,
      modifiedAuthor: '',
      minCreatedDate: '',
      maxCreatedDate: '',
      minModifiedDate: '',
      maxModifiedDate: '',
    };
    setFormFieldState(resetFormState);
    setFormState(resetFormState);
  };

  return (
    <Form onSubmit={handleSubmit} className="font-montserrat">
      <Form.Group widths="equal" className="">
        <Form.Select
          label="Status"
          name="status"
          options={statusOption}
          value={status}
          icon={<i className="dropdown icon text-wildfire-orange" />}
          onChange={handleDropdownChange}
        />
        <Form.Select
          search
          label="Payable Application"
          placeholder="-"
          name="payableApplicationId"
          options={applicationOptions}
          value={payableApplicationId}
          onChange={handleDropdownChange}
          icon={<i className="dropdown icon text-wildfire-orange" />}
        />
        <Form.Select
          search
          label="Created By"
          placeholder="-"
          name="modifiedAuthor"
          options={modifiedAuthorOptions}
          value={modifiedAuthor}
          onChange={handleDropdownChange}
          icon={<i className="dropdown icon text-wildfire-orange" />}
        />
      </Form.Group>
      <div className="flex w-full justify-center items-center flex-wrap gap-4">
        <div className="flex items-center rounded-md border-[1px] px-3 py-2 bg-[#F9FAFB] justify-center xs:w-full md:w-auto lg:mr-4 justify-center">
          <h3 className="font-montserrat font-semibold text-[13px] mr-3 mb-0">Created Date</h3>
          <Form.Group widths="equal" className="!mb-0">
            <Form.Input
              name="minCreatedDate"
              type="date"
              min="2017-07-01"
              max={dateToday}
              value={minCreatedDate}
              onChange={e => setFormFieldState({ ...formFieldState, minCreatedDate: e.target.value })}
            />
            <Form.Input
              name="maxCreatedDate"
              type="date"
              min={minCreatedDate ?? '2017-07-01'}
              max={dateToday}
              value={maxCreatedDate}
              onChange={e => setFormFieldState({ ...formFieldState, maxCreatedDate: e.target.value })}
            />
          </Form.Group>
        </div>
        <div className="flex items-center rounded-md border-[1px] px-3 py-2 bg-[#F9FAFB] justify-center xs:w-full md:w-auto">
          <h3 className="font-montserrat font-semibold text-[13px] mr-3 mb-0">Last Updated</h3>
          <Form.Group widths="equal" className="!mb-0">
            <Form.Input
              name="stateDate"
              type="date"
              min="2017-07-01"
              max={dateToday}
              value={minModifiedDate}
              onChange={e => setFormFieldState({ ...formFieldState, minModifiedDate: e.target.value })}
            />
            <Form.Input
              name="endDate"
              type="date"
              min={minModifiedDate ?? '2017-07-01'}
              max={dateToday}
              value={maxModifiedDate}
              onChange={e => setFormFieldState({ ...formFieldState, maxModifiedDate: e.target.value })}
            />
          </Form.Group>
        </div>
        <div className="mx-3 flex flex-nowrap justify-center xs:w-full lg:w-auto lg:justify-start">
          <Button className="orange-rounded-button" type="submit" id="submitBtn">
            Apply
          </Button>
          <Button className="grey-rounded-button" id="clearBtn" onClick={handleClear}>
            Clear
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default TxInquiryFilters;
