//@ts-nocheck
import React from 'react';
import PartnerPaymentsTable from './PartnerPaymentsTable';
import { Payment } from 'pages/Payments/types';

interface PartnerPaymentsProps {
  paymentsList: Payment[];
  isLoading: boolean;
}

const PartnerPayments = ({ paymentsList, isLoading }: PartnerPaymentsProps) => {
  // Combine payments with the same payableApplicationId
  const combinedPayments = paymentsList.reduce((acc, current) => {
    const existing = acc.find(item => item.payableApplicationId === current.payableApplicationId);
    if (existing) {
      existing.processor += `, ${current.processor}`; // Concatenate processor strings
    } else {
      acc.push({ ...current }); // Add new payment object
    }
    return acc;
  }, [] as Payment[]);

  return (
    <div className="w-full p-5">
      <h3 className="font-montserrat font-semibold mb-8">Partner Payments</h3>
      <PartnerPaymentsTable paymentsList={combinedPayments} isLoading={isLoading} />
    </div>
  );
};

export default PartnerPayments;
