import React, { useState, useEffect } from 'react';
import { Table, TableBody } from 'semantic-ui-react';
import CommissionRateQueueTableHeader from './CommissionRateQueueTableHeader';
import CommissionRateQueueTableRow from './CommissionRateQueueTableRow';
import { CommissionRateQueueItem, CommissionRateQueueStatus } from './types';
import { Network } from 'redux/store/network/types';

interface CommissionRateQueueTableProps {
  data: CommissionRateQueueItem[];
  networks: Network[];
  status: CommissionRateQueueStatus;
  showNeedsReviewAtTop: boolean;
  onApprove: (item: CommissionRateQueueItem) => void;
  onRevise: (item: CommissionRateQueueItem) => void;
}

const CommissionRateQueueTable: React.FC<CommissionRateQueueTableProps> = ({
  data,
  networks,
  status,
  showNeedsReviewAtTop,
  onApprove,
  onRevise,
}) => {
  const [filteredData, setFilteredData] = useState<CommissionRateQueueItem[]>(data);

  useEffect(() => {
    if (showNeedsReviewAtTop) {
      const needsReview = data.filter(item => item.suggestedDisplayName.toLowerCase().includes('needs review'));
      const others = data.filter(item => !item.suggestedDisplayName.toLowerCase().includes('needs review'));
      setFilteredData([...needsReview, ...others]);
    } else {
      setFilteredData(data);
    }
  }, [data, showNeedsReviewAtTop]);

  return (
    <div className="bg-white mt-4 p-5 rounded-lg shadow-md overflow-x-auto font-montserrat">
      <Table celled striped aria-label="commission rate queue table" className="w-full max-w-[1350px]">
        <CommissionRateQueueTableHeader status={status} />
        <TableBody>
          {filteredData.map(item => (
            <CommissionRateQueueTableRow
              key={item.reviewItemId}
              networkName={networks.find(network => network.ID === item.meta.networkMerchant.networkId)?.Name || ''}
              item={item}
              status={status}
              onApprove={onApprove}
              onRevise={onRevise}
            />
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default CommissionRateQueueTable;
