import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Loader, Pagination } from 'semantic-ui-react';
import { getBonusPaymentsList } from 'actions/reconciles/reconciles';
import {
  TxInquiryArgs,
  TxInquiryFormState,
  TxInquiry as TxInquiryList,
  TxInquiryResponse,
  TxInquirySummary,
} from 'components/TxInquiry/types';
import TxInquiryTable from 'components/TxInquiry/TxInquiryTable';
import TxInquiryFilters from 'components/TxInquiry/TxInquiryFilters';
import TxInquiryStats from 'components/TxInquiry/TxInquiryStats';

const defaultFormState = {
  status: 'PENDING',
  payableApplicationId: 0,
  modifiedAuthor: '',
  minCreatedDate: '',
  maxCreatedDate: '',
  minModifiedDate: '',
  maxModifiedDate: '',
};

interface TxInquiryProps {
  getBonusPaymentsList: (args: TxInquiryArgs) => Promise<TxInquiryResponse>;
}

const TxInquiry: React.FC<TxInquiryProps> = ({ getBonusPaymentsList }) => {
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isListLoading, setIsListLoading] = useState(false);
  const [txInquiryList, setTxInquiryList] = useState<TxInquiryList[]>([]);
  const [formState, setFormState] = useState<TxInquiryFormState>(defaultFormState);
  const [summary, setSummary] = useState<TxInquirySummary>({} as TxInquirySummary);

  const handlePaginationChange = (e: React.MouseEvent<HTMLAnchorElement>, { activePage }: any) => {
    setActivePage(activePage);
  };

  useEffect(() => {
    const {
      status,
      modifiedAuthor,
      minCreatedDate,
      maxCreatedDate,
      minModifiedDate,
      maxModifiedDate,
      payableApplicationId,
    } = formState;

    const fetchBonusPaymentsList = async () => {
      if (!status) return;
      try {
        setIsListLoading(true);
        const response = await getBonusPaymentsList({
          pageNumber: activePage,
          status,
          modifiedAuthor,
          minCreatedDate,
          maxCreatedDate,
          minModifiedDate,
          maxModifiedDate,
          payableApplicationID: payableApplicationId,
        });
        setTotalPages(response.totalPages || 1);
        setTxInquiryList(response.bonusPayments || []);
        setSummary(response.summary || {});
      } catch (error) {
        toast.error('Failed to retrieve transaction inquiries');
        console.error(error);
      } finally {
        setIsListLoading(false);
      }
    };

    fetchBonusPaymentsList();
  }, [activePage, formState]);

  return (
    <div className="w-full text-center">
      <h1 className="font-title">Transaction Inquiry Dashboard</h1>
      <div className="mb-5">
        <TxInquiryStats txInquirySummary={summary} />
      </div>
      <div className="bg-white min-h-[200px] w-full rounded-md shadow-lg p-5">
        <div className="rounded-md border-[1px] bg-white p-5 ">
          <TxInquiryFilters txInquiryList={txInquiryList} formState={formState} setFormState={setFormState} />
        </div>
        <div className="max-w-[1350px] mx-auto my-5">
          <TxInquiryTable txInquiryList={txInquiryList} />
        </div>
        <Pagination
          activePage={activePage}
          onPageChange={handlePaginationChange}
          totalPages={totalPages}
          data-testid="tx-inquiry-pagination"
        />
      </div>
    </div>
  );
};

const mapActionsToProp = {
  getBonusPaymentsList,
};

export default connect(null, mapActionsToProp)(TxInquiry);
