import { wfGet, wfPut, wfPost } from '../../actions';

export const getKeywordTargetsByKeywordID = keywordID => async dispatch => {
  try {
    const res = await dispatch(wfGet(`/api/keyword/${keywordID}/target`));
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateKeywordTargetByID = (targetID, body) => async dispatch => {
  try {
    const res = await dispatch(wfPut(`/api/keyword/${targetID}/target`, body));
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addKeywordTargetByKeywordID = (keywordID, body) => async dispatch => {
  try {
    const res = await dispatch(wfPost(`/api/keyword/${keywordID}/target`, body));
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getKeywordTargetQueue = args => async dispatch => {
  const { status, page } = args;
  const qs = `?status=${status}&page=${page}`;

  try {
    const res = await dispatch(wfGet(`/api/keyword-target-queue${qs}`));
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateKeywordTargetQueueByID = (id, body) => async dispatch => {
  try {
    const res = await dispatch(wfPut(`/api/keyword-target-queue/${id}`, body));
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};
