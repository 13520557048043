import React, { useState } from 'react';
import { Icon, Table } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import TruncatedTableCell from 'components/CommissionSearch/TruncatedTableCell';
import TruncatedCopyCell from 'components/TruncatedCopyCell/TruncatedCopyCell';
import PendingPayoutForm from 'components/PendingPayout/PendingPayoutForm';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import {
  IPendingPayoutList,
  IApplicationItem,
  INetworkMerchantSearchResult,
  IDeviceIDResponse,
  IAddBonusPaymentsData,
} from './types';
import { Network } from 'redux/store/network/types';
interface IPendingPayoutTableProps {
  pendingPayoutList: IPendingPayoutList[];
  networks: Network[];
  applications: IApplicationItem[];
  getNetworkMerchantSearchResults: (search: string, networkId: number) => Promise<INetworkMerchantSearchResult[]>;
  getDeviceIDByTrackingCode: (args: {
    appId: string;
    trackingCode: string;
    eventDate: string;
  }) => Promise<IDeviceIDResponse>;
  updateBonusPayment: (args: { id: number; body: IAddBonusPaymentsData }) => Promise<void>;
  fetchPendingPayoutList: () => void;
  deleteBonusPayment: (id: number) => Promise<void>;
}

const tableColumns = [
  { name: 'Actions', key: 'actions' },
  { name: 'Network', key: 'network' },
  { name: 'Network Merchant', key: 'networkMerchant' },
  { name: 'Application', key: 'payableApplication' },
  { name: 'Modified Author', key: 'modifiedAuthor' },
  { name: 'Sale Amount', key: 'saleAmount' },
  { name: 'Commission Amount', key: 'commissionAmount' },
  { name: 'Bonus Amount', key: 'bonusAmount' },
  { name: 'Event Date', key: 'eventDate' },
  { name: 'Device ID', key: 'deviceId' },
  { name: 'Merchant Order ID', key: 'merchantOrderId' },
  { name: 'Tracking Code', key: 'trackingCode' },
];

const PendingPayoutTable = ({
  pendingPayoutList,
  networks,
  applications,
  getNetworkMerchantSearchResults,
  getDeviceIDByTrackingCode,
  updateBonusPayment,
  fetchPendingPayoutList,
  deleteBonusPayment,
}: IPendingPayoutTableProps) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<number | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleDelete = async () => {
    if (!selectedItem) return;
    try {
      await deleteBonusPayment(selectedItem);
      fetchPendingPayoutList();
      toast.success('Successfully deleted pending payout');
    } catch (error) {
      console.error(error);
      toast.error('Failed to delete pending payout. Please try again');
    } finally {
      setIsDeleteModalOpen(false);
    }
  };

  const actionsCell = (item: IPendingPayoutList) => {
    return (
      <div className="flex items-center gap-2">
        <button
          title="Edit"
          onClick={() => {
            setIsEditModalOpen(true);
            setSelectedItem(item.id);
          }}
          className="orange-rounded-button flex items-center justify-center w-[20px] h-[20px] pb-[3px] pl-[3px]"
        >
          <Icon className="!text-xs" name="pencil" />
        </button>
        <button
          title="Delete"
          onClick={() => {
            setIsDeleteModalOpen(true);
            setSelectedItem(item.id);
          }}
          className="orange-rounded-button flex items-center justify-center w-[20px] h-[20px] pb-[3px] pl-[3px]"
        >
          <Icon className="!text-xs" name="close" />
        </button>
      </div>
    );
  };

  const tableRows = pendingPayoutList.map(item => (
    <Table.Row key={item.id} className="text-sm whitespace-nowrap">
      <Table.Cell>{actionsCell(item)}</Table.Cell>
      <Table.Cell>{item.networkName}</Table.Cell>
      <Table.Cell>{item.networkMerchantName}</Table.Cell>
      <TruncatedTableCell>{item.payableApplicationName}</TruncatedTableCell>
      <Table.Cell>{item.modifiedAuthor}</Table.Cell>
      <Table.Cell className="!text-right font-dm-mono">{`${item.saleAmount} ${item.saleCurrencyCode}`}</Table.Cell>
      <Table.Cell className="!text-right font-dm-mono">{`${item.commissionAmount} ${item.applicationCurrencyCode}`}</Table.Cell>
      <Table.Cell className="!text-right font-dm-mono">{`${parseFloat(item.usdBonusAmount).toFixed(2)} USD`}</Table.Cell>
      <Table.Cell>{item.eventDate.split('T')[0]}</Table.Cell>
      <Table.Cell>{item.deviceId}</Table.Cell>
      <Table.Cell>{item.merchantOrderId}</Table.Cell>
      <TruncatedCopyCell text={item.trackingCode.toString()} />
    </Table.Row>
  ));

  return (
    <>
      <div className="font-montserrat overflow-auto mx-auto w-full">
        <Table celled>
          <Table.Header className="xs:!hidden sm:!table-header-group">
            <Table.Row className="text-center">
              {tableColumns.map(column => (
                <Table.HeaderCell key={column.key} className="text-sm font-normal whitespace-nowrap">
                  {column.name}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Body>{tableRows}</Table.Body>
        </Table>
      </div>
      {isEditModalOpen && (
        <PendingPayoutForm
          submitMode="edit"
          networks={networks}
          applications={applications}
          isOpen={isEditModalOpen && selectedItem !== null}
          initialData={pendingPayoutList.find(item => item.id === selectedItem)}
          getNetworkMerchantSearchResults={getNetworkMerchantSearchResults}
          getDeviceIDByTrackingCode={getDeviceIDByTrackingCode}
          onSubmit={updateBonusPayment}
          fetchPendingPayoutList={fetchPendingPayoutList}
          setIsOpen={setIsEditModalOpen}
          onClose={() => setSelectedItem(null)}
        />
      )}
      {isDeleteModalOpen && (
        <ConfirmationModal
          action="delete the pending payout"
          modalOpen={isDeleteModalOpen}
          setModalOpen={setIsDeleteModalOpen}
          onConfirm={handleDelete}
        />
      )}
    </>
  );
};

export default PendingPayoutTable;
