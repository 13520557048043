import React, { useState, ChangeEvent, useEffect } from 'react';

interface InputV2Props {
  className?: string;
  label: string;
  id: string;
  value: string;
  type?: 'text' | 'number' | 'date';
  placeholder?: string;
  error?: boolean;
  errorMessage?: string;
  min?: number;
  max?: number;
  title?: string;
  disabled?: boolean;
  onChange: (value: string) => void;
  onBlur?: () => void;
}

const InputV2 = ({
  className = '',
  label,
  placeholder,
  id,
  value,
  error,
  errorMessage,
  type = 'text',
  min,
  max,
  title,
  onChange,
  onBlur,
  disabled = false,
}: InputV2Props) => {
  const [userInput, setUserInput] = useState(value || '');

  useEffect(() => {
    setUserInput(value);
  }, [value]);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;

    setUserInput(input);
    onChange(input);
  };

  const clearInput = () => {
    setUserInput('');
    onChange('');
  };

  return (
    <div className={`relative w-full max-w-xl mx-auto mb-[10px] ${className}`}>
      <label htmlFor={id} className={`mb-[5px] text-[13px] font-bold ${error ? 'text-red-500' : ''}`}>
        {label}
      </label>
      <div className="relative">
        <input
          className={`w-full p-2  border rounded-lg focus:outline-none
         ${
           !error ? 'focus:ring-1 focus:ring-blue-500' : ''
         } ${error ? 'border-red-500 text-red-500 bg-red-100' : 'border-gray-300'}`}
          id={id}
          type={type}
          value={value || userInput}
          required
          onChange={handleOnChange}
          placeholder={placeholder || ''}
          min={min}
          max={max}
          title={title}
          onBlur={onBlur}
          disabled={disabled}
        />

        {userInput && type === 'text' && (
          <button className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500" onClick={clearInput}>
            ✕
          </button>
        )}
      </div>
      {error && errorMessage && <p className="text-red-500 ">{errorMessage}</p>}
    </div>
  );
};

export default InputV2;
