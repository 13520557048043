import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, Loader, Modal, Search } from 'semantic-ui-react';
import { debounce, isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import {
  IApplicationItem,
  IFormattedNetworkMerchantSearchResult,
  IFormErrors,
  INetworkMerchantSearchResult,
  IDeviceIDResponse,
  IAddBonusPaymentsData,
  IPendingPayoutList,
} from './types';
import { Network } from 'redux/store/network/types';
import { objectKeysToLowerCase, generateCommissionAmount } from './helpers';
import InputV2 from 'components/Input/InputV2';
import Autocomplete from 'components/Autocomplete/Autocomplete';
import { currencyOptions } from './constants';
import { wfUserAgent } from 'helpers';

interface IPendingPayoutFormProps {
  submitMode: 'add' | 'edit';
  networks: Network[];
  applications: IApplicationItem[];
  isOpen: boolean;
  initialData?: IPendingPayoutList; // Will only be provided if submitMode is 'edit'
  getNetworkMerchantSearchResults: (search: string, networkId: number) => Promise<INetworkMerchantSearchResult[]>;
  getDeviceIDByTrackingCode: (args: {
    appId: string;
    trackingCode: string;
    eventDate: string;
  }) => Promise<IDeviceIDResponse>;
  fetchPendingPayoutList: () => void;
  onSubmit:
    | ((args: IAddBonusPaymentsData) => Promise<void>) // Add new pending payout
    | ((args: { id: number; body: IAddBonusPaymentsData }) => Promise<void>); // Edit existing pending payout
  setIsOpen: (isOpen: boolean) => void;
  onClose?: () => void;
}

const PendingPayoutForm = ({
  submitMode,
  networks,
  applications,
  isOpen,
  initialData,
  getNetworkMerchantSearchResults,
  getDeviceIDByTrackingCode,
  fetchPendingPayoutList,
  onSubmit,
  setIsOpen,
  onClose,
}: IPendingPayoutFormProps) => {
  const [networkMerchantId, setNetworkMerchantId] = useState(initialData?.networkMerchantId || 0);
  const [merchantOrderId, setMerchantOrderId] = useState(initialData?.merchantOrderId || '');
  const [saleCurrencyCode, setSaleCurrencyCode] = useState(initialData?.saleCurrencyCode || '');
  const [haveDeviceID, setHaveDeviceID] = useState(false);
  const [networkId, setNetworkId] = useState(initialData?.networkId?.toString() || '');
  const [searchResults, setSearchResults] = useState<IFormattedNetworkMerchantSearchResult[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchingDeviceId, setIsFetchingDeviceId] = useState(false);
  const [trackingCodeBlurred, setTrackingCodeBlurred] = useState(false);
  const [formErrors, setFormErrors] = useState<IFormErrors>({});
  const [selectedNetworkMerchantName, setSelectedNetworkMerchantName] = useState('');
  const [saleAmount, setSaleAmount] = useState(initialData?.saleAmount || '');
  const [commissionRate, setCommissionRate] = useState(0);
  const [commissionAmount, setCommissionAmount] = useState(initialData?.commissionAmount || '');
  const [deviceIdStatus, setDeviceIdStatus] = useState('');
  const [deviceId, setDeviceId] = useState(initialData?.deviceId || 0);
  const [eventDate, setEventDate] = useState(initialData?.eventDate?.split('T')[0] || '');
  const [applicationId, setApplicationId] = useState(initialData?.applicationId || '');
  const [trackingCode, setTrackingCode] = useState(initialData?.trackingCode || '');
  const [isCreatingPendingPayout, setIsCreatingPendingPayout] = useState(false);

  const isInEditMode = submitMode === 'edit' && initialData;

  const isValidPendingPayoutForm = (): boolean => {
    const newErrors: IFormErrors = {};

    if (isEmpty(networkId)) {
      newErrors.networkId = 'Network ID is required';
    }
    if (networkMerchantId === 0) {
      newErrors.networkMerchantId = 'Network Merchant ID is required';
    }

    setFormErrors(newErrors);
    return isEmpty(newErrors);
  };

  const getOptions = (data: (IApplicationItem | Network)[]) => {
    return data.map(item => ({
      key: item.ID,
      text: `${item.ID} - ${item.Name}`,
      name: item.Name,
      value: item.ID,
    }));
  };

  const fetchNetworkMerchantSearchResults = async (
    searchQuery: string,
    networkId: number,
    setSearchResults: (results: IFormattedNetworkMerchantSearchResult[]) => void,
    setIsLoading: (loading: boolean) => void,
  ) => {
    if (searchQuery.length < 3) return;
    if (!networkId) return;

    setIsLoading(true);
    try {
      const results: INetworkMerchantSearchResult[] = await getNetworkMerchantSearchResults(
        encodeURIComponent(searchQuery),
        networkId,
      );
      const formattedResults: any[] = results.map(result => {
        return {
          ...objectKeysToLowerCase(result),
        };
      });
      setSearchResults(formattedResults);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onSetSearchQuery = useCallback(
    debounce((searchQuery: string): void => {
      fetchNetworkMerchantSearchResults(searchQuery, Number(networkId), setSearchResults, setIsLoading);
    }, 500),
    [networkId],
  );

  const renderSearchResults = ({ resourceid, name, disabled }: any) => (
    <p className={disabled ? 'pointer-events-none opacity-50' : ''}>{`${resourceid} - ${name}`}</p>
  );

  const handleChangeNetworkMerchant = (id: number, name: string, disabled: boolean) => {
    if (disabled) return;

    if (isEmpty(networkMerchantId)) {
      setFormErrors({ ...formErrors, networkMerchantId: '' });
    }

    setNetworkMerchantId(id);
    setSelectedNetworkMerchantName(name);
    setSearchResults([]);
    setSearchQuery('');
  };

  const handleClose = () => {
    setIsOpen(false);
    setNetworkMerchantId(0);
    setMerchantOrderId('');
    setSaleCurrencyCode('');
    setHaveDeviceID(false);
    setDeviceIdStatus('');
    setDeviceId(0);
    setApplicationId('');
    setEventDate('');
    setTrackingCode('');
    setTrackingCodeBlurred(false);
    onClose?.();
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isValidPendingPayoutForm()) return;

    try {
      setIsCreatingPendingPayout(true);
      const payloadData = {
        CommissionAmount: commissionAmount,
        DeviceID: Number(deviceId),
        EventDate: new Date(eventDate).toISOString(),
        MerchantOrderID: merchantOrderId,
        ModifiedAuthor: localStorage.getItem('userEmail') || wfUserAgent(),
        NetworkID: Number(networkId),
        NetworkMerchantID: Number(networkMerchantId),
        SaleAmount: saleAmount,
        SaleCurrencyCode: saleCurrencyCode,
        TrackingCode: trackingCode,
      };
      if (submitMode === 'add') {
        await (onSubmit as (args: IAddBonusPaymentsData) => Promise<void>)(payloadData);
      } else {
        await (onSubmit as (args: { id: number; body: IAddBonusPaymentsData }) => Promise<void>)({
          id: initialData?.id || 0,
          body: payloadData,
        });
      }
      if (submitMode === 'add') {
        toast.success('Pending payout created successfully');
      } else {
        toast.success('Pending payout was updated successfully');
      }
      fetchPendingPayoutList();
      handleClose();
    } catch (error) {
      console.error(error);
      if (submitMode === 'add') {
        toast.error('Failed to create new pending payout');
      } else {
        toast.error('Failed to update the pending payout. Please check the values and try again');
      }
    } finally {
      setIsCreatingPendingPayout(false);
    }
  };

  useEffect(() => {
    if (!saleAmount || !commissionRate) {
      return;
    }
    // Every time the commission rate or sale amount changes, calculate the commission amount
    const calculatedCommissionAmount = generateCommissionAmount(commissionRate, Number(saleAmount));
    setCommissionAmount(calculatedCommissionAmount);
  }, [commissionRate, saleAmount]);

  useEffect(() => {
    if (isInEditMode) return; // If in edit mode, don't fetch device ID

    if (applicationId && eventDate && trackingCode && trackingCodeBlurred) {
      setDeviceIdStatus('Finding device ID');
      const fetchDeviceId = async () => {
        try {
          setIsFetchingDeviceId(true);
          const response = await getDeviceIDByTrackingCode({
            appId: applicationId.toString(),
            trackingCode,
            eventDate,
          });

          if (response.deviceId) {
            setDeviceId(response.deviceId);
            setDeviceIdStatus('SUCCESS');
            toast.success('Device ID found');
          }
        } catch (error) {
          console.error(error);
          setDeviceIdStatus('FAILED');
          setFormErrors({ ...formErrors, deviceId: 'Failed to find device ID' });
          toast.error('Failed to find device ID');
        } finally {
          setIsFetchingDeviceId(false);
        }
      };
      fetchDeviceId();
    } else {
      setDeviceIdStatus('');
    }

    setFormErrors({});
  }, [applicationId, eventDate, trackingCode, trackingCodeBlurred]);

  return (
    <Modal closeIcon closeOnDimmerClick={false} className="font-montserrat" open={isOpen} onClose={handleClose}>
      <Modal.Content>
        <h3 className="!font-montserrat !text-[16px]">
          {isInEditMode ? 'Edit an existing transaction inquiry' : 'Add a new transaction inquiry'}
        </h3>
        <div className="border border-gray-300 rounded-md p-5 bg-[#F9FAFB]">
          {isCreatingPendingPayout ? (
            <Loader active className="after:!border-t-[#767676]" size="huge" inline="centered" />
          ) : (
            <Form onSubmit={handleSubmit}>
              <div className="flex flex-row justify-between mb-2 gap-5">
                <div className="flex flex-wrap !w-full !flex-col">
                  {isInEditMode ? ( // If in edit mode, show the device ID input with initial value
                    <InputV2
                      id="device-id-input"
                      label="Device ID"
                      value={deviceId === 0 ? '' : deviceId.toString()}
                      error={!!formErrors.deviceId}
                      errorMessage={formErrors.deviceId}
                      onChange={(value: string) => {
                        // Removes any non-numeric characters
                        const numericValue = value.replace(/[^0-9]/g, '');
                        setDeviceId(numericValue === '' ? 0 : Number(numericValue));
                      }}
                    />
                  ) : (
                    <div className="border border-gray-300 rounded-md p-5 mb-5">
                      <Form.Radio
                        className="!mb-3"
                        label="I have a device ID"
                        name="haveDeviceID"
                        checked={haveDeviceID}
                        onChange={() => {
                          setHaveDeviceID(true);
                          setDeviceIdStatus('');
                          setDeviceId(0);
                        }}
                      />
                      <Form.Radio
                        label="I don't have a device ID"
                        name="HaveNoDeviceID"
                        checked={!haveDeviceID}
                        onChange={() => {
                          setHaveDeviceID(false);
                          setDeviceIdStatus('');
                          setDeviceId(0);
                        }}
                      />
                      {!haveDeviceID ? ( // If the user doesn't have a device ID, show the application ID input
                        <>
                          <div className="my-5 text-[12px] italic">
                            {isFetchingDeviceId ? (
                              <span>Finding device ID</span>
                            ) : deviceIdStatus === 'SUCCESS' ? (
                              <span className="text-success-green">Device ID found</span>
                            ) : deviceIdStatus === '' ? (
                              <span>Process may take longer</span>
                            ) : (
                              <span className="text-failed-red">Failed to find device ID</span>
                            )}
                          </div>
                          <Form.Dropdown
                            clearable
                            fluid
                            selection
                            search
                            label="Application ID"
                            name="applicationId"
                            className="!text-sm mt-2.5"
                            placeholder="Select an application"
                            disabled={isFetchingDeviceId}
                            icon={<i className="dropdown icon text-wildfire-orange" />}
                            options={getOptions(applications)}
                            renderLabel={label => ({
                              content: label.text,
                              className:
                                '!bg-wildfire-orange !border-0 !text-white !font-medium !shadow-none !text-[11px]',
                            })}
                            onChange={(_, data) => setApplicationId(data.value?.toString() || '')}
                          />
                        </>
                      ) : (
                        // If the user has a device ID, show the device ID input
                        <InputV2
                          id="device-id-input"
                          label="Device ID"
                          value={deviceId === 0 ? '' : deviceId.toString()}
                          onChange={(value: string) => {
                            setDeviceId(value === '' ? 0 : Number(value));
                          }}
                        />
                      )}
                    </div>
                  )}
                  <InputV2
                    id="event-date-input"
                    label="Event Date"
                    type="date"
                    value={eventDate}
                    disabled={isFetchingDeviceId}
                    error={!!formErrors.eventDate}
                    errorMessage={formErrors.eventDate}
                    onChange={(value: number | string) => setEventDate(value.toString())}
                  />
                </div>
                <div className="flex flex-wrap justify-start !w-full !flex-col">
                  <InputV2
                    id="tracking-code-input"
                    label="Tracking Code"
                    value={trackingCode}
                    disabled={isFetchingDeviceId}
                    error={!!formErrors.trackingCode}
                    errorMessage={formErrors.trackingCode}
                    onChange={(value: number | string) => {
                      setTrackingCode(value.toString());
                      setTrackingCodeBlurred(false);
                    }}
                    onBlur={() => setTrackingCodeBlurred(true)}
                  />
                  <Form.Dropdown
                    clearable
                    fluid
                    selection
                    search
                    label="Network"
                    name="networks"
                    className="!text-base mt-2.5"
                    placeholder="Select a network"
                    icon={<i className="dropdown icon text-wildfire-orange" />}
                    defaultValue={initialData?.networkId}
                    options={getOptions(networks)}
                    renderLabel={label => ({
                      content: label.text,
                      className: '!bg-wildfire-orange !border-0 !text-white !font-medium !shadow-none !text-[11px]',
                    })}
                    onChange={(_, data) => setNetworkId(data.value?.toString() || '')}
                  />
                  {formErrors.networkId && <p className="text-red-500 ">{formErrors.networkId}</p>}
                  <div className="mb-[8px]">
                    <label htmlFor="network-merchant" className="mb-[5px] text-[13px] font-bold ">
                      Network Merchant
                    </label>
                    <Search
                      id="network-merchant"
                      fluid
                      input={{
                        fluid: true,
                        placeholder: isInEditMode
                          ? networkMerchantId === null || networkId === null || isEmpty(selectedNetworkMerchantName)
                            ? `${initialData?.networkMerchantId} - ${initialData?.networkMerchantName}`
                            : `${networkMerchantId} - ${selectedNetworkMerchantName}`
                          : networkMerchantId === null || networkId === null || isEmpty(selectedNetworkMerchantName)
                            ? ' '
                            : `${networkMerchantId} - ${selectedNetworkMerchantName}`,
                        iconPosition: 'left',
                      }}
                      value={searchQuery}
                      loading={isLoading}
                      onResultSelect={(_: any, data: any) => {
                        handleChangeNetworkMerchant(data.result.id, data.result.name, data.result.disabled);
                      }}
                      onSearchChange={(event: any): void => {
                        event.persist();
                        if (isEmpty(networkId)) {
                          setFormErrors({ ...formErrors, networkMerchantId: 'Select a network' });
                          return;
                        } else {
                          setFormErrors({ ...formErrors, networkMerchantId: '' });
                        }
                        setSearchQuery(event.target.value);
                        onSetSearchQuery(event.target.value);
                      }}
                      results={searchResults}
                      resultRenderer={renderSearchResults}
                      open={searchResults.length > 0}
                      onBlur={() => {
                        setSearchResults([]);
                        setSearchQuery('');
                      }}
                      noResultsMessage="No options found."
                    />
                    {(!!formErrors.networkMerchantId || !!networkId) && (
                      <p className="text-red-500 ">{formErrors.networkMerchantId}</p>
                    )}
                  </div>
                  <InputV2
                    id="merchant-order-id-input"
                    label="Merchant Order ID"
                    error={!!formErrors.merchantOrderId}
                    errorMessage={formErrors.merchantOrderId}
                    value={merchantOrderId}
                    onChange={(value: string) => setMerchantOrderId(value)}
                  />
                </div>
                <div className="flex flex-wrap justify-start !flex-col !w-full">
                  <InputV2
                    id="sale-amount-input"
                    label="Sale Amount"
                    value={saleAmount}
                    error={!!formErrors.saleAmount}
                    errorMessage={formErrors.saleAmount}
                    onChange={(value: string) => {
                      setSaleAmount(value);
                    }}
                  />
                  <InputV2
                    id="commission-rate-input"
                    label="Commission Rate (%)"
                    type="number"
                    value={commissionRate.toString()}
                    max={100}
                    error={!!formErrors.commissionRate}
                    errorMessage={formErrors.commissionRate}
                    onChange={(value: string) => {
                      setCommissionRate(Number(value));
                    }}
                  />
                  <InputV2
                    id="commission-amount-input"
                    label="Commission Amount"
                    value={commissionAmount}
                    error={!!formErrors.commissionAmount}
                    errorMessage={formErrors.commissionAmount}
                    onChange={(value: string) => {
                      setCommissionAmount(value);
                    }}
                  />
                  <Autocomplete
                    required
                    className="min-w-[80px]"
                    label="Sale Currency Code"
                    id="sale-currency-code-autocomplete"
                    error={!!formErrors.saleCurrencyCode}
                    errorMessage={formErrors.saleCurrencyCode}
                    value={saleCurrencyCode}
                    onOptionClick={(value: string | number) => {
                      if (typeof value === 'string') {
                        setSaleCurrencyCode(value);
                      }
                    }}
                    options={currencyOptions}
                  />
                </div>
              </div>
              <div className="flex justify-center">
                <Button type="submit" disabled={!deviceId} className="orange-rounded-button !text-sm">
                  Submit
                </Button>
              </div>
            </Form>
          )}
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default PendingPayoutForm;
