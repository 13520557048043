import React, { useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from 'semantic-ui-react';
import { getDevice, setBadAuth, setCanEdit } from 'actions';
import { checkIsWildfireAdmin, checkIsDQContractor, checkIsReconciler, checkIsOperations } from 'helpers/auth0';
import { encryptValue } from 'helpers/encrypt';
import AgingReportContainer from 'pages/AgingReport/AgingReportContainer';
import BonusPayments from 'pages/BonusPayments/BonusPayments';
import BonusToolContainer from 'pages/BonusTool/BonusToolContainer';
import CommissionRateQueue from 'pages/CommissionRateQueue/CommissionRateQueue';
import CommissionSearch from 'pages/CommissionSearch/CommissionSearch';
import FeatureDashboard from 'pages/FeatureFlags/FeatureFlagDashboard';
import FraudCommissions from 'pages/FraudCommissions/FraudCommissions';
import JSONConversionTool from 'pages/JSONConversionTool/JSONConverstionTool';
import KeywordTargetQueue from 'pages/KeywordTargetQueue/KeywordTargetQueue';
import KeywordTargetsContainer from 'pages/KeywordTargets/KeywordTargetsContainer';
import MerchantStatsContainer from 'pages/MerchantStats/MerchantStatsContainer';
import NetworkMerchantOnboardingContainer from 'pages/NetworkMerchantOnboarding/NetworkMerchantOnboardingContainer';
import RecentlyOnboardedMerchants from 'pages/RecentlyOnboardedMerchants/RecentlyOnboardedMerchants';
import ReconciliationContainer from 'pages/Reconciliation/ReconciliationContainer';
import ReconciliationJobContainer from 'pages/Reconciliation/ReconciliationJobContainer';
import ReconciliationJobItemsContainer from 'pages/Reconciliation/ReconciliationJobItemsContainer';
import SalesDashboardContainer from 'pages/SalesDashboard/SalesDashboardContainer';
import PrivateRoute from './components/Route/PrivateRoute/PrivateRoute';
import Auth from './pages/Auth/Auth';
import Home from './pages/Home/Home';
import MerchantAdminContainer from './pages/MerchantAdmin/MerchantAdminContainer';
import SalesDashboardContainerV2 from './pages/SalesDashboardV2/SalesDashboardContainerV2';
import TechSupportContainer from './pages/TechSupport/TechSupportContainer';
import Unauthorized from './pages/Unauthorized/Unauthorized';
import Payments from './pages/Payments/Payments';
import TxInquiry from 'pages/TxInquiry/TxInquiry';
import TxInquiryPendingPayoutList from 'pages/TxInquiry/PendingPayout';

const Main = () => {
  const dispatch = useDispatch();
  const deviceToken = useSelector(state => state.user.deviceToken);
  const auth0Token = useSelector(state => state.user.auth0Token);
  const { isAuthenticated, user } = useAuth0();
  const [isLoading, setIsLoading] = useState(false);
  const [isWildfireAdmin, setIsWildfireAdmin] = useState(false);
  const [isDQContractor, setIsDQContractor] = useState(false);
  const [isReconciler, setIsReconciler] = useState(false);
  const [isOperations, setIsOperations] = useState(false);

  useEffect(() => {
    if (!isAuthenticated) {
      return dispatch(setBadAuth());
    }

    setIsWildfireAdmin(checkIsWildfireAdmin(user));

    if (!isWildfireAdmin) {
      setIsDQContractor(checkIsDQContractor(user));
    }
    setIsReconciler(checkIsReconciler(user));

    dispatch(setCanEdit(checkIsOperations(user)));
    setIsOperations(checkIsOperations(user));

    const hasAppPermission = isWildfireAdmin || isDQContractor;
    if (!deviceToken && hasAppPermission) {
      setIsLoading(true);
      dispatch(getDevice());
    } else {
      setIsLoading(false);
    }

    if (user?.email && !localStorage.getItem('encryptedId')) {
      localStorage.setItem('encryptedId', encryptValue(user.email));
    }
  }, [deviceToken, isAuthenticated, auth0Token, isWildfireAdmin, isDQContractor, dispatch, user]);

  if (isLoading) return <Loader active size="massive" inline="centered" />;

  return (
    <div className="main">
      <Switch>
        <Route path="/auth" component={Auth} />{' '}
        <PrivateRoute exact path="/" component={isWildfireAdmin || isDQContractor ? Home : Unauthorized} />
        {(isWildfireAdmin || isDQContractor) && [
          <PrivateRoute path="/merchant-admin" key="/merchant-admin" component={MerchantAdminContainer} />,
          <PrivateRoute
            path="/network-merchant-onboarding"
            key="/network-merchant-onboarding"
            component={NetworkMerchantOnboardingContainer}
          />,
          <PrivateRoute path="/keyword-targets" key="/keyword-targets" component={KeywordTargetsContainer} />,
          <PrivateRoute path="/keyword-target-queue" key="/keyword-target-queue" component={KeywordTargetQueue} />,
          <PrivateRoute path="/commission-rate-queue" key="/commission-rate-queue" component={CommissionRateQueue} />,
        ]}
        {isWildfireAdmin && [
          <PrivateRoute path="/fraud-commissions" key="/fraud-commissions" component={FraudCommissions} />,
          <PrivateRoute path="/sales-dashboard" key="/sales-dashboard" component={SalesDashboardContainerV2} />,
          <PrivateRoute path="/old-sales-dashboard" key="/old-sales-dashboard" component={SalesDashboardContainer} />,
          <PrivateRoute path="/tech-support" key="/tech-support" component={TechSupportContainer} />,
          <PrivateRoute path="/merchant-stats" key="/merchant-stats" component={MerchantStatsContainer} />,
          <PrivateRoute path="/aging-report" key="/aging-report" component={AgingReportContainer} />,
          <PrivateRoute path="/bonus-tool" key="/bonus-tool" component={BonusToolContainer} />,
          <PrivateRoute path="/json-conversion-tool" key="/json-conversion-tool" component={JSONConversionTool} />,
          <PrivateRoute path="/feature-flags" key="/feature-flags" component={FeatureDashboard} />,
          <PrivateRoute path="/commission-search" key="/commission-search" component={CommissionSearch} />,
          <PrivateRoute
            path="/recently-onboarded-merchants"
            key="/recently-onboarded-merchants"
            component={RecentlyOnboardedMerchants}
          />,
        ]}
        {isReconciler && (
          <PrivateRoute path="/reconciliation/:id/merchant/:mid" component={ReconciliationJobItemsContainer} />
        )}
        {isOperations && [
          <PrivateRoute path="/bonus-payments" component={BonusPayments} />, // TODO: Remove once transaction inquiries are fully implemented
          <PrivateRoute path="/transaction-inquiry-pending-payout-list" component={TxInquiryPendingPayoutList} />,
          <PrivateRoute path="/transaction-inquiry-dashboard" component={TxInquiry} />,
        ]}
        {isReconciler && [
          <PrivateRoute path="/reconciliation/:id" component={ReconciliationJobContainer} />,
          <PrivateRoute path="/reconciliation" component={ReconciliationContainer} />,
          <PrivateRoute path="/payments" key="/payments" component={Payments} />,
        ]}
      </Switch>
    </div>
  );
};

export default Main;
