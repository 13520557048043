import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { capitalize } from 'lodash';
import { Dropdown, DropdownItemProps, Pagination, Loader } from 'semantic-ui-react';
import { getKeywordTargetQueue, updateKeywordTargetQueueByID } from 'actions/keywordTargets/keywordTargets';
import {
  CouponTargetAssignment,
  CouponTargetAssignmentStatus,
  PageInfo,
  UpdateKeywordTargetQueueByIDBody,
  KeywordTargetQueueResponse,
} from './types';
import KeywordTargetQueueTable from '../../components/KeywordTargetQueue/KeywordTargetQueueTable';
import { wfUserAgent, findErrorMessage } from 'helpers';

const statusOptions: DropdownItemProps[] = [
  { text: 'Pending', value: 'PENDING', key: 'pending', name: 'Pending' },
  { text: 'Blocked', value: 'BLOCKED', key: 'blocked', name: 'Blocked' },
  { text: 'Ignored', value: 'IGNORED', key: 'ignored', name: 'Ignored' },
  { text: 'Finalized', value: 'FINALIZED', key: 'finalized', name: 'Finalized' },
];

interface KeywordTargetQueueProps {
  getKeywordTargetQueue: (args: {
    status: CouponTargetAssignmentStatus;
    page: number;
  }) => Promise<KeywordTargetQueueResponse>;
  updateKeywordTargetQueueByID: (id: number, body: UpdateKeywordTargetQueueByIDBody) => Promise<void>;
}

export const KeywordTargetQueue = ({
  getKeywordTargetQueue,
  updateKeywordTargetQueueByID,
}: KeywordTargetQueueProps) => {
  const urlParams = new URLSearchParams(window.location.search);
  const history = useHistory();
  const initialPage = urlParams.get('page') || 1;
  const initialStatus: CouponTargetAssignmentStatus =
    (urlParams.get('status') as CouponTargetAssignmentStatus) || 'PENDING';

  const [activeStatusFilter, setActiveStatusFilter] = useState<CouponTargetAssignmentStatus>(initialStatus);
  const [activePage, setActivePage] = useState(Number(initialPage));
  const [couponTargetAssignments, setCouponTargetAssignments] = useState<CouponTargetAssignment[]>([]);
  const [pageInfo, setPageInfo] = useState<PageInfo>({
    TotalPages: 0,
    TotalItems: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdatingCouponTargetAssignment, setIsUpdatingCouponTargetAssignment] = useState(false);
  const [error, setError] = useState('');

  const fetchKeywordTargetQueue = async (status: CouponTargetAssignmentStatus, page: number) => {
    try {
      setIsLoading(true);
      const response = await getKeywordTargetQueue({ status, page });
      setCouponTargetAssignments(response.Items || []);
      setPageInfo(response.PageInfo || { TotalPages: 0, TotalItems: 0 });
    } catch (error) {
      toast.error('Failed to get Keyword target queue.');
      if (typeof error === 'string') {
        setError(error);
      } else if (error instanceof Error) {
        setError(capitalize(error.message) || 'Unknown error has occurred.');
      } else {
        setError('Unknown error has occurred.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleOnActionButtonClick = async (id: number, status: 'BLOCKED' | 'IGNORED') => {
    const userEmail = localStorage.getItem('userEmail') || wfUserAgent();
    try {
      setIsUpdatingCouponTargetAssignment(true);
      await updateKeywordTargetQueueByID(id, {
        ID: id,
        Status: status,
        ModifiedAuthor: userEmail,
      });
      fetchKeywordTargetQueue(status, activePage);
      toast.success('Successfully updated status!');
    } catch (error) {
      toast.error(`Failed to update coupon target assignment ID: ${id}`);
      setError(findErrorMessage(error) || 'Unknown error has occurred.');
    } finally {
      setIsUpdatingCouponTargetAssignment(false);
    }
  };

  useEffect(() => {
    urlParams.set('page', activePage.toString());
    urlParams.set('status', activeStatusFilter);

    history.push(`?${urlParams.toString()}`);

    const page = urlParams.get('page') || 1;
    const status = urlParams.get('status') || 'PENDING';

    // status is a string, so we need to cast it to a CouponTargetAssignmentStatus
    fetchKeywordTargetQueue(status as CouponTargetAssignmentStatus, Number(page));
  }, [activeStatusFilter, activePage]);

  return (
    <div className="w-full font-montserrat">
      <h1 className="text-center font-title">Keyword Target Queue</h1>
      <div className="bg-white rounded-md mt-8 p-5 rounded-md shadow-md shadow-[5px_5px_10px_-3px_#a8a8a8]">
        <div className="max-w-[1350px] mx-auto">
          <div className="flex justify-between mb-5 xs:flex-wrap xs:gap-5">
            <Dropdown
              basic
              placeholder="Select Status"
              selection
              data-testid="status-dropdown"
              onChange={(_, { value }) => {
                setActiveStatusFilter(value as CouponTargetAssignmentStatus);
                setActivePage(1);
              }}
              value={activeStatusFilter}
              options={statusOptions}
              icon={<i className="dropdown icon text-wildfire-orange" />}
            />
            <Pagination
              className="overflow-auto"
              activePage={activePage}
              onPageChange={(_, { activePage }) => setActivePage(Number(activePage))}
              totalPages={pageInfo?.TotalPages}
            />
          </div>
          {Boolean(error) && !isLoading && (
            <div>
              <span className="text-failed-red">{error}</span>
            </div>
          )}
          {isLoading ? (
            <Loader active size="massive" inline="centered" />
          ) : (
            !Boolean(error) && (
              <KeywordTargetQueueTable
                couponTargetAssignments={couponTargetAssignments}
                activeStatusFilter={activeStatusFilter}
                isUpdatingCouponTargetAssignment={isUpdatingCouponTargetAssignment}
                handleOnActionButtonClick={handleOnActionButtonClick}
              />
            )
          )}
        </div>
      </div>
    </div>
  );
};

const mapActionsToProps = {
  getKeywordTargetQueue,
  updateKeywordTargetQueueByID,
};

export default connect(null, mapActionsToProps)(KeywordTargetQueue);
