import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Segment, Icon, Accordion, Label } from 'semantic-ui-react';
import { getUserCanEdit } from 'selectors/user';
import { IMAGE_KINDS } from '../../../constants';
import AddImage from '../AddImage/AddImage';
import MerchantImage from '../MerchantImage/MerchantImage';

class ImageList extends Component {
  state = {
    isVisible: false,
  };

  toggleIsVisible = () => this.setState({ isVisible: !this.state.isVisible });

  render() {
    const { isVisible } = this.state;
    const { images, addMerchantImage, removeMerchantImage, viewOnly, canEdit } = this.props;
    const isViewOnly = viewOnly ?? !canEdit;
    const featuredImages = images.filter(image => image.Kind === IMAGE_KINDS.FEATURED.NAME);
    const logoImages = images.filter(image => image.Kind === IMAGE_KINDS.LOGO.NAME);
    const logoRectImages = images.filter(image => image.Kind === IMAGE_KINDS.LOGORECT.NAME);
    const logoTransparentImages = images.filter(image => image.Kind === IMAGE_KINDS.LOGOTRANSPARENT.NAME);

    return (
      <Segment.Group className="image-list">
        <Segment>
          <Accordion>
            <Accordion.Title active={isVisible} className="flex justify-between">
              <h3 className="m-0 flex-1" onClick={this.toggleIsVisible}>
                <Icon name="image" />
                Images ({images.length})
              </h3>
              {!isViewOnly && <AddImage addMerchantImage={addMerchantImage} />}
            </Accordion.Title>
            <Accordion.Content active={isVisible}>
              <h4>
                Featured
                {isViewOnly && <Label style={{ marginLeft: '5px' }}>660px x 380px</Label>}
              </h4>
              <div className="inline-block mb-5">
                {featuredImages.length ? (
                  featuredImages.map(image => (
                    <MerchantImage
                      key={image.ID}
                      image={image}
                      removeMerchantImage={removeMerchantImage}
                      viewOnly={isViewOnly}
                    />
                  ))
                ) : (
                  <div>None</div>
                )}
              </div>
              <h4>
                Logo
                {isViewOnly && <Label style={{ marginLeft: '5px' }}>200px x 200px</Label>}
              </h4>
              <div className="inline-block mb-5">
                {logoImages.length ? (
                  logoImages.map(image => (
                    <MerchantImage
                      key={image.ID}
                      image={image}
                      removeMerchantImage={removeMerchantImage}
                      viewOnly={isViewOnly}
                    />
                  ))
                ) : (
                  <div>None</div>
                )}
              </div>
              <h4>
                LogoRect
                {isViewOnly && <Label style={{ marginLeft: '5px' }}>260px x 200px</Label>}
              </h4>
              <div className="inline-block mb-5">
                {logoRectImages.length ? (
                  logoRectImages.map(image => (
                    <MerchantImage
                      key={image.ID}
                      image={image}
                      removeMerchantImage={removeMerchantImage}
                      viewOnly={isViewOnly}
                    />
                  ))
                ) : (
                  <div>None</div>
                )}
              </div>
              <h4>
                LogoTransparent
                {isViewOnly && <Label style={{ marginLeft: '5px' }}>260px x 200px</Label>}
              </h4>
              <div className="inline-block">
                {logoTransparentImages.length ? (
                  logoTransparentImages.map(image => (
                    <MerchantImage
                      key={image.ID}
                      image={image}
                      removeMerchantImage={removeMerchantImage}
                      viewOnly={isViewOnly}
                    />
                  ))
                ) : (
                  <div>None</div>
                )}
              </div>
            </Accordion.Content>
          </Accordion>
        </Segment>
      </Segment.Group>
    );
  }
}

ImageList.propTypes = {
  images: PropTypes.array.isRequired,
  addMerchantImage: PropTypes.func.isRequired,
  removeMerchantImage: PropTypes.func.isRequired,
  viewOnly: PropTypes.bool,
  canEdit: PropTypes.bool,
};

const mapStateToProps = state => ({
  canEdit: getUserCanEdit(state),
});

export default connect(mapStateToProps, null)(ImageList);
