import React from 'react';
import { TableHeaderCell } from 'semantic-ui-react';
import { CouponTargetAssignmentStatus } from 'pages/KeywordTargetQueue/types';

interface KeywordTargetQueueHeadersProps {
  activeStatusFilter: CouponTargetAssignmentStatus;
}

const KeywordTargetQueueTableHeaders = ({ activeStatusFilter }: KeywordTargetQueueHeadersProps) => {
  return (
    <thead>
      <tr>
        <TableHeaderCell className="one wide !text-center text-sm">ID</TableHeaderCell>
        <TableHeaderCell className="one wide !text-center text-sm">Merchant</TableHeaderCell>
        <TableHeaderCell className="six wide !text-center text-sm">Keyword</TableHeaderCell>
        <TableHeaderCell className="one wide !text-center text-sm whitespace-nowrap">
          Madmin Keyword Link
        </TableHeaderCell>
        {activeStatusFilter === 'PENDING' && (
          <TableHeaderCell className="one wide !text-center text-sm">Actions</TableHeaderCell>
        )}
      </tr>
    </thead>
  );
};

export default KeywordTargetQueueTableHeaders;
