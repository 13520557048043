import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PartnerPayments from 'components/Payments/PartnerPayments';
import { getPayments } from 'actions/reconciles/reconciles';
import { Payment } from './types';

interface PaymentsProps {
  getPayments: () => Promise<Payment[]>;
}

const Payments = ({ getPayments }: PaymentsProps) => {
  const [paymentsList, setPaymentsList] = useState<Payment[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchPaymentsList = async () => {
      setIsLoading(true);
      try {
        const response = await getPayments();
        setPaymentsList(response);
      } catch (error) {
        console.error('Failed to retrieve payments list', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPaymentsList();
  }, []);

  return (
    <div className="text-center w-full max-w-[1350px] mx-auto">
      <h1 className="font-title">Payments</h1>
      <div className="flex bg-white rounded-md shadow-md w-full mt-8 p-5">
        <PartnerPayments paymentsList={paymentsList} isLoading={isLoading} />
      </div>
    </div>
  );
};

const mapActionsToProp = {
  getPayments,
};

export default connect(null, mapActionsToProp)(Payments);
