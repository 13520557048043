import React, { useState } from 'react';
import { TxInquirySummary } from './types';
import TxInquiryAppSummaryModal from './TxInquiryAppSummaryModal';

interface TxInquiryStatsProps {
  txInquirySummary: TxInquirySummary;
}

const TxInquiryStats: React.FC<TxInquiryStatsProps> = ({ txInquirySummary }) => {
  const [openAppStatModal, setOpenAppStatModal] = useState(false);

  return (
    <div className="flex justify-start items-end font-montserrat">
      <div className="bg-white rounded-md shadow-lg min-w-[170px] h-[130px] mr-5 border-l-[5px] border-wildfire-orange p-3">
        <p className="text-xs uppercase pt-5">Bonus Payment Count</p>
        <p className="text-[35px] pt-3">{txInquirySummary.bonusPaymentCount || 0}</p>
      </div>
      <div className="bg-white rounded-md shadow-lg min-w-[170px] h-[130px] mr-5 border-l-[5px] border-wildfire-orange p-3">
        <p className="text-xs uppercase pt-5">Bonus Payment Total</p>
        <p className="text-[35px] pt-3">{`$${parseFloat(txInquirySummary.bonusPaymentTotal || '0').toFixed(2)}`}</p>
      </div>
      <span
        className="uppercase text-xs text-blue-500 underline cursor-pointer"
        onClick={() => setOpenAppStatModal(true)}
      >
        Stats for each application
      </span>
      <TxInquiryAppSummaryModal
        modalOpen={openAppStatModal}
        setModalOpen={setOpenAppStatModal}
        summaryByApp={txInquirySummary.byApplication || []}
      />
    </div>
  );
};

export default TxInquiryStats;
