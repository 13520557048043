import React from 'react';
import { Button, Modal } from 'semantic-ui-react';

interface CounterProps {
  action: string;
  className?: string;
  modalOpen: boolean;
  setModalOpen: (value: boolean) => void;
  onConfirm: () => void;
}

const ConfirmationModal = ({ action, className = '', modalOpen, setModalOpen, onConfirm }: CounterProps) => {
  return (
    <div>
      <Modal
        onClose={() => setModalOpen(false)}
        onOpen={() => setModalOpen(true)}
        open={modalOpen}
        size="tiny"
        centered={true}
        className={className}
      >
        <Modal.Content className="text-center font-montserrat">
          <p>Are you sure you want to {action}?</p>
          <div className="flex justify-center mt-3 gap-2">
            <Button className="!font-montserrat" color="green" onClick={onConfirm}>
              Confirm
            </Button>
            <Button className="!font-montserrat" color="red" onClick={() => setModalOpen(false)}>
              Cancel
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    </div>
  );
};

export default ConfirmationModal;
