export const allFlags = {
  /*
  example: {
    name: "example", // value must match the name of the property holding this object
    title: "Test Feature", // will be displayed in Feature Flag page as label
    description: "A test feature to ensure that flagging is working correctly.", // will be displayed in Feature Flag page underneath label
    enabled: false //controls whether the flag is currently active or not
  }
  */
  test: {
    name: 'test',
    title: 'Test',
    description: 'A test feature to show off feature flagging. When enabled, the homepage title should be red',
    enabled: false,
  },
  enablePayments: {
    name: 'enablePayments',
    title: 'Enable Payments',
    description: 'If enabled the payments page will be available',
    enabled: false,
  },
  enableTransactionInquiry: {
    name: 'enableTransactionInquiry',
    title: 'Enable Transaction Inquiry',
    description: 'If enabled the new transaction inquiry page will be available',
    enabled: false,
  },
};
