import React from 'react';
import Papa from 'papaparse';
import { Button } from 'semantic-ui-react';
import { TxInquiry } from './types';

interface TxInquiryTableDownloadProps {
  tableData: TxInquiry[];
  getCommissionID: (txInquiry: TxInquiry) => number;
}

const TxInquiryTableDownload = ({ tableData, getCommissionID }: TxInquiryTableDownloadProps) => {
  const downloadCSV = () => {
    const orderedData = tableData.map(row => ({
      Network: row.networkName,
      Merchant: row.networkMerchantName,
      PayableApplication: `${row.payableApplicationId} - ${row.payableApplicationName}`,
      CommissionID: getCommissionID(row),
      CreatedDate: row.createdDate.split('T')[0],
      CreatedBy: row.modifiedAuthor,
      SaleAmount: `${row.saleAmount} ${row.saleCurrencyCode}`,
      CommissionAmount: `${row.commissionAmount} ${row.applicationCurrencyCode}`,
      Status: row.status,
      LastUpdated: row.modifiedDate.split('T')[0],
      USDPayout: `${row.usdBonusAmount} USD`,
    }));

    const csv = Papa.unparse(orderedData);
    const blob = new Blob([csv], { type: 'text/csv' });
    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(blob);
    const dateToday = new Date().toISOString().split('T')[0];
    const fileName = `tx-inquiry-${dateToday}`;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  return (
    <div className="flex justify-end">
      <Button className="orange-rounded-button" onClick={downloadCSV}>
        Download Table
      </Button>
    </div>
  );
};

export default TxInquiryTableDownload;
