export const currencyOptions = [
  {
    label: 'USD - United States Dollar',
    value: 'USD',
  },
  {
    label: 'EUR - Euro',
    value: 'EUR',
  },
  {
    label: 'JPY - Japanese Yen',
    value: 'JPY',
  },
  {
    label: 'GBP - British Pound Sterling',
    value: 'GBP',
  },
  {
    label: 'AUD - Australian Dollar',
    value: 'AUD',
  },
  {
    label: 'CAD - Canadian Dollar',
    value: 'CAD',
  },
  {
    label: 'CHF - Swiss Franc',
    value: 'CHF',
  },
  {
    label: 'CNY - Chinese Yuan Renminbi',
    value: 'CNY',
  },
  {
    label: 'SEK - Swedish Krona',
    value: 'SEK',
  },
  {
    label: 'NZD - New Zealand Dollar',
    value: 'NZD',
  },
];
