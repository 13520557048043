import { wfGet, wfPost, wfPatch, wfPut, wfDelete } from '..';

export const createReconcileJob = reconcileData => async dispatch => {
  const formData = new FormData();
  Object.entries(reconcileData).forEach(([key, value]) => formData.append(key, value));
  try {
    const res = await dispatch(wfPost(`/api/reconcile/create`, { formData }));
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getReconcileJobs =
  ({ page_size, page, network_id }) =>
  async dispatch => {
    let qs = `?page_size=${page_size}&page=${page}`;
    if (network_id) {
      qs += `&network_id=${network_id}`;
    }
    try {
      const res = await dispatch(wfGet(`/api/reconcile/jobs${qs}`));
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getReconcileJob =
  ({ page_size, page, id, status }) =>
  async dispatch => {
    let qs = `?page_size=${page_size}&page=${page}`;
    if (status) {
      qs += `&status=${status}`;
    }
    try {
      const res = await dispatch(wfGet(`/api/reconcile/${id}${qs}`));
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getReconcileJobItems =
  ({ page_size, page, id, nmxid }) =>
  async dispatch => {
    let qs = `?page_size=${page_size}&page=${page}`;
    try {
      const res = await dispatch(wfGet(`/api/reconcile/${id}/merchant/${nmxid}${qs}`));
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getReconcileJobMerchants =
  ({ id }) =>
  async dispatch => {
    try {
      const res = await dispatch(wfGet(`/api/reconcile/${id}/merchants`));
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const retryReconcileJob =
  ({ id }) =>
  async dispatch => {
    try {
      const res = await dispatch(wfPatch(`/api/reconcile/${id}/retry`));
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getDeviceIDByTrackingCode = args => async dispatch => {
  const { appId, trackingCode, eventDate } = args;

  try {
    const res = await dispatch(
      wfGet(`/api/reconcile/device-id/${appId}?trackingCode=${trackingCode}&eventDate=${eventDate}`),
    );
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getBonusPaymentsList = args => async dispatch => {
  const {
    pageNumber,
    status,
    payableApplicationID,
    modifiedAuthor,
    minCreatedDate,
    maxCreatedDate,
    minModifiedDate,
    maxModifiedDate,
  } = args;

  const params = new URLSearchParams({
    pageNumber,
    status,
    ...(payableApplicationID && { payableApplicationID }),
    ...(modifiedAuthor && { modifiedAuthor }),
    ...(minCreatedDate && { minCreatedDate }),
    ...(maxCreatedDate && { maxCreatedDate }),
    ...(minModifiedDate && { minModifiedDate }),
    ...(maxModifiedDate && { maxModifiedDate }),
  });

  const qs = `?${params.toString()}`;

  try {
    const res = await dispatch(wfGet(`/api/reconcile/get/bonus-payments${qs}`));
    return res;
  } catch (error) {
    return Promise.reject();
  }
};

export const addBonusPayment = body => async dispatch => {
  try {
    const res = await dispatch(wfPost(`/api/reconcile/bonus-payments/create`, body));
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateBonusPayment = args => async dispatch => {
  const { id, body } = args;
  try {
    const res = await dispatch(wfPut(`/api/reconcile/bonus-payments/${id}`, body));
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteBonusPayment = id => async dispatch => {
  try {
    const res = await dispatch(wfDelete(`/api/reconcile/bonus-payments/${id}`));
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getPayments = () => async dispatch => {
  try {
    const res = await dispatch(wfGet(`/api/reconcile/payments`));
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};
