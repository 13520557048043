import { IFormattedNetworkMerchantSearchResult, INetworkMerchantSearchResult } from './types';

export const objectKeysToLowerCase = (result: INetworkMerchantSearchResult): IFormattedNetworkMerchantSearchResult => {
  const newObject: any = {};

  newObject.id = result.id;
  newObject.name = result.name;
  newObject.disabled = result.disabled;
  newObject.networkid = result.networkID;
  newObject.networkname = result.networkName;
  newObject.resourceid = result.id;
  newObject.title = '';

  return newObject;
};

export const generateCommissionAmount = (commissionRate: number, saleAmount: number) => {
  if (!commissionRate || !saleAmount) return '0';
  return ((commissionRate / 100) * saleAmount).toFixed(2).toString();
};
