import React, { useState, useEffect, SyntheticEvent } from 'react';
import { uniq, uniqBy } from 'lodash';
import { FileUpload, FileUploadSelectEvent, ItemTemplateOptions } from 'primereact/fileupload';
import { InputSwitch } from 'primereact/inputswitch';
import { Select, Modal, Icon, Loader, Dimmer, Segment, Button, Input } from 'semantic-ui-react';
import ColorSelectIcon from './ColorSelectIcon';
import { isValidImageKind } from './helpers';
import { ImageDetail, ImageKindOption, ErrorType, ImageKindNames } from './types';
import { IMAGE_KINDS } from '../../../constants';

const MAX_SIZE = 3;
const MARGIN = {
  FEATURED: 20,
  LOGORECT: 15,
  LOGO: 10,
  LOGOTRANSPARENT: 0,
};
const DEFAULT_BRAND_COLOR = '#000000';

interface AddImageProps {
  addMerchantImage: (formData: FormData) => void;
}

const imageKindOptions: ImageKindOption[] = [
  { text: IMAGE_KINDS.FEATURED.NAME, value: IMAGE_KINDS.FEATURED.NAME },
  { text: IMAGE_KINDS.LOGO.NAME, value: IMAGE_KINDS.LOGO.NAME },
  { text: IMAGE_KINDS.LOGORECT.NAME, value: IMAGE_KINDS.LOGORECT.NAME },
  { text: IMAGE_KINDS.LOGOTRANSPARENT.NAME, value: IMAGE_KINDS.LOGOTRANSPARENT.NAME },
];

const getImageTopLeftPixelColor = (image: HTMLImageElement) => {
  // Create a temporary canvas to get color of top left pixel in image
  const tempCanvas = document.createElement('canvas');
  tempCanvas.width = image.width;
  tempCanvas.height = image.height;
  const tempCtx = tempCanvas.getContext('2d')!;
  tempCtx.drawImage(image, 0, 0);
  const topLeftPixel = tempCtx.getImageData(0, 0, 1, 1).data;
  return `rgba(${topLeftPixel[0]}, ${topLeftPixel[1]}, ${topLeftPixel[2]}, ${topLeftPixel[3] / 255})`;
};

const isLogoTransparent = (kind: string) => kind === 'LOGOTRANSPARENT';

const AddImage = ({ addMerchantImage }: AddImageProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [dimensionErrors, setDimensionErrors] = useState<ErrorType>({});
  const [generalErrors, setGeneralErrors] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [imageDetails, setImageDetails] = useState<ImageDetail[]>([]);
  const [canUpload, setCanUpload] = useState(false);

  const removePreview = (file: File, callback: () => void) => {
    const imageDetailsCopy = [...imageDetails];
    const imageToRemoveIndex = imageDetailsCopy.findIndex(
      imageDetail => imageDetail?.originalImage?.name === file.name,
    );
    imageDetailsCopy.splice(imageToRemoveIndex, 1);
    setImageDetails(imageDetailsCopy);
    setDimensionErrors({});
    callback();
  };

  const dropZone = () => {
    return (
      <div className="drag-drop-contents flex flex-wrap justify-center p-70 w-full">
        <Icon className="!text-[5em] !opacity-30 !w-full !mb-[30px]" name="cloud upload" />
        <span>Drag and Drop Images Here</span>
      </div>
    );
  };

  const validateUpload = () => {
    const hasCorrectDimensions = (image: ImageDetail) => {
      const { width, height, kind } = image;
      return width === IMAGE_KINDS[kind].WIDTH && height == IMAGE_KINDS[kind].HEIGHT;
    };

    if (imageDetails.every(hasCorrectDimensions) && imageDetails.length > 0) {
      setCanUpload(true);
    }
  };

  const getImageKind = (width: number, height: number): ImageKindNames => {
    // If the image dimensions match any of the image kinds, return the kind
    const imageKinds = [IMAGE_KINDS.FEATURED, IMAGE_KINDS.LOGO, IMAGE_KINDS.LOGORECT, IMAGE_KINDS.LOGOTRANSPARENT];
    return imageKinds.find(kind => kind.WIDTH === width && kind.HEIGHT === height)?.NAME || 'FEATURED';
  };

  const setShouldAddMargin = (fileName: string, shouldAddMargin: boolean) => {
    const image = imageDetails.filter(imageDetail => imageDetail.originalImage?.name === fileName)[0];
    resizeImage({ ...image, shouldAddMargin }, image.backgroundColorOverride || image.backgroundColor);
  };

  const resizeImage = (imageToResize: ImageDetail, bgColor: string) => {
    const imageToResizeCopy = { ...imageToResize };
    const { kind, originalImage } = imageToResizeCopy;
    if (!originalImage) return;
    const imageDetailsCopy = imageDetails.map(imageDetail =>
      imageDetail.originalImage === imageToResizeCopy.originalImage ? imageToResizeCopy : { ...imageDetail },
    );
    const targetWidth = IMAGE_KINDS[kind].WIDTH;
    const targetHeight = IMAGE_KINDS[kind].HEIGHT;
    const reader = new FileReader();
    URL.createObjectURL(originalImage);

    reader.onload = e => {
      const img = new Image();

      img.onload = () => {
        // Calculate the margin and adjust dimensions to accommodate it
        const margin = imageToResizeCopy.shouldAddMargin ? MARGIN[kind] : 0;
        const adjustedWidth = targetWidth - margin * 2;
        const adjustedHeight = targetHeight - margin * 2;

        // Calculate the scale factor while maintaining aspect ratio
        const scaleX = adjustedWidth / img.width;
        const scaleY = adjustedHeight / img.height;
        const scale = Math.min(scaleX, scaleY);

        const newWidth = Math.floor(img.width * scale);
        const newHeight = Math.floor(img.height * scale);

        // Create target canvas
        const targetCanvas = document.createElement('canvas');
        targetCanvas.width = targetWidth;
        targetCanvas.height = targetHeight;
        const targetCtx = targetCanvas.getContext('2d');

        if (targetCtx) {
          targetCtx.fillStyle = bgColor;
          targetCtx.fillRect(0, 0, targetWidth, targetHeight);

          // Draw the resized image centered in the target canvas, adjusted for margin
          const offsetX = Math.floor((targetWidth - newWidth) / 2);
          const offsetY = Math.floor((targetHeight - newHeight) / 2);
          targetCtx.drawImage(img, 0, 0, img.width, img.height, offsetX, offsetY, newWidth, newHeight);

          // Convert the final canvas to a blob directly
          targetCanvas.toBlob(blob => {
            // Convert the blob into a File object
            if (blob) {
              const newFile = new File([blob], originalImage.name, { type: originalImage.type });
              imageToResizeCopy.editedImage = newFile;
            }

            // Update dimensions without changing the original
            imageToResizeCopy.width = targetWidth;
            imageToResizeCopy.height = targetHeight;
            imageToResizeCopy.editInProgress = true;
            imageToResizeCopy.backgroundColorOverride = bgColor;
            setImageDetails(imageDetailsCopy);
          }, originalImage.type);
        }
      };

      img.onerror = () => console.error('Error loading image');
      if (typeof e.target?.result === 'string') {
        img.src = e.target.result;
      }
    };

    reader.onerror = () => console.error('FileReader error');
    reader.readAsDataURL(originalImage);
  };

  const validateImages = () => {
    setCanUpload(false);
    const newErrors: ErrorType = {};

    imageDetails.forEach(imageDetail => {
      const { kind, width, height, originalImage, editInProgress } = imageDetail;

      if (!width || !height || !originalImage) return;

      if (editInProgress && dimensionErrors[originalImage.name]) {
        newErrors[originalImage.name] = dimensionErrors[originalImage.name];
      }

      if (!(width === IMAGE_KINDS[kind].WIDTH && height === IMAGE_KINDS[kind].HEIGHT)) {
        switch (kind) {
          case 'FEATURED':
            newErrors[originalImage.name] = 'Featured images should be 660px x 380px.';
            break;
          case 'LOGO':
            newErrors[originalImage.name] = 'Logo images should be 200px x 200px.';
            break;
          case 'LOGORECT':
            newErrors[originalImage.name] = 'LogoRect images should be 260px x 200px.';
            break;
          case 'LOGOTRANSPARENT':
            newErrors[originalImage.name] = 'LogoTransparent images should be 560px x 232px.';
            break;
          default:
            newErrors[originalImage.name] = 'Image dimensions are invalid';
        }
      }
    });

    setDimensionErrors(newErrors);
    validateUpload();
  };

  const handleSelect = (e: FileUploadSelectEvent) => {
    const newGeneralErrors = [...generalErrors];
    const files = e.files as File[];

    // For images that are dragged into the drop zone
    if (e.originalEvent && 'dataTransfer' in e.originalEvent) {
      const allFiles: File[] = Array.from(e.originalEvent.dataTransfer?.files ?? []);

      //  Images with file size that exceeds max size is rejected but this is to display a reason for rejection
      allFiles.forEach(file => {
        if (file.size > 1024 * 1024 * MAX_SIZE) {
          newGeneralErrors.push(`Maximum upload size is ${MAX_SIZE}MB.`);
          const uniqueErrors = uniq(newGeneralErrors);
          setGeneralErrors(uniqueErrors);
        }
      });
    }

    if (files.length) {
      files.forEach(file => {
        if (!file.type.includes('image')) return;
        const objectURL = URL.createObjectURL(file); // Workaround to avoid getting errors around objectURL not being a property of type File

        if (file.type.includes('image')) {
          const image = new Image();
          image.src = objectURL;
          image.onload = () => {
            const backgroundColor = getImageTopLeftPixelColor(image);
            const kind = getImageKind(image.width, image.height);
            if (!backgroundColor) throw new Error('File is not an image');

            setImageDetails(images =>
              uniqBy(
                [
                  ...images,
                  {
                    width: image.width,
                    height: image.height,
                    originalImage: file,
                    backgroundColor,
                    kind,
                    ordinal: 1,
                    shouldAddMargin: false,
                    editInProgress: false,
                    ...(isLogoTransparent(kind) ? { brandColor: DEFAULT_BRAND_COLOR } : {}),
                  },
                ],
                details => details.originalImage?.name.split('.')[0],
              ),
            );
          };
        }
      });
    }
  };

  const handleUpload = () => {
    const newErrors = [...generalErrors];
    if (imageDetails.length) {
      imageDetails.forEach(async ({ kind, ordinal, editedImage, originalImage, brandColor }) => {
        const formData = new FormData();
        const imageToUpload = editedImage || originalImage;
        const userEmail = localStorage.getItem('userEmail');
        const modifiedAuthor = userEmail ? userEmail : 'merchant admin image create';
        const colorMeta = {
          Kind: 'BRAND', // We currently only need BRAND colors
          Color: brandColor,
          DefaultColor: false, // Always false for now
        };

        formData.append('kind', kind);
        formData.append('ordinal', ordinal.toString());
        formData.append('image', imageToUpload);
        formData.append('ModifiedAuthor', modifiedAuthor);

        if (isLogoTransparent(kind)) {
          formData.append('colorMeta', JSON.stringify(colorMeta));
        }

        try {
          setIsLoading(true);
          await addMerchantImage(formData);
          setGeneralErrors([]);
          setImageDetails([]);
          setIsLoading(false);
          setModalOpen(false);
        } catch (err) {
          if (typeof err === 'string') {
            newErrors.push(err);
            setGeneralErrors(newErrors);
          } else if (err instanceof Error) {
            newErrors.push(err.message || 'Unknown error has occurred.');
            setGeneralErrors(newErrors);
            setIsLoading(false);
          }
        }
      });
    }
  };

  const closeGeneralError = (newError: string) => {
    const newErrors = [...generalErrors];
    const errorIndex = newErrors.findIndex(error => error === newError);
    newErrors.splice(errorIndex, 1);
    setGeneralErrors(newErrors);
  };

  const closeDimensionError = (imageName: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [imageName]: _, ...remainingErrors } = dimensionErrors;
    setDimensionErrors(remainingErrors);
  };

  const confirmImageResize = (image: ImageDetail) => {
    setImageDetails(
      imageDetails.map(imageDetail =>
        imageDetail.originalImage === image.originalImage ? { ...image, editInProgress: false } : { ...imageDetail },
      ),
    );
  };

  const imageControls = (file: object, options: ItemTemplateOptions) => {
    const actualFile = file as File;
    if (!actualFile) return <></>;

    // If not an image file, remove preview image
    if (!actualFile.type.includes('image')) {
      options.onRemove({} as SyntheticEvent<Element, Event>);
    }

    const imageToDisplay = imageDetails.filter(imageDetail => imageDetail?.originalImage?.name === actualFile.name)[0];

    if (!imageToDisplay) return <></>;

    const {
      originalImage,
      editedImage,
      width,
      height,
      backgroundColor,
      shouldAddMargin,
      editInProgress,
      brandColor,
      kind,
    } = imageToDisplay;

    const objectURL = URL.createObjectURL(editedImage || originalImage);
    const imageKind = getImageKind(width, height);

    return (
      <div className="p-2 bg-[#fafafc] border border-gray-300 rounded m-2 flex flex-wrap">
        {originalImage?.name && originalImage.name in dimensionErrors && (
          <div
            key={originalImage.name}
            className="flex justify-between items-center bg-[rgba(255,231,230,0.7)] border-0 p-3 rounded-md m-3 text-[rgba(255,87,87,1)] w-full"
          >
            <div className="message">
              <div className="text-left">
                {dimensionErrors[originalImage.name]}
                <div className="flex items-center my-[5px]">
                  <div className="flex items-center h-[2.5rem] bg-[rgb(244,216,216)] p-[0.8rem] mr-[5px] rounded-md">
                    <span className="mr-[0.25em]">
                      {isLogoTransparent(kind)
                        ? 'Background will be transparent for LOGOTRANSPARENT images'
                        : 'Select background color to resize:'}
                    </span>
                    {isLogoTransparent(kind) ? (
                      <ColorSelectIcon
                        color={backgroundColor}
                        onClick={() => resizeImage(imageToDisplay, backgroundColor)}
                      />
                    ) : (
                      <>
                        <ColorSelectIcon
                          color={backgroundColor}
                          onClick={() => resizeImage(imageToDisplay, backgroundColor)}
                        />
                        <ColorSelectIcon color="black" onClick={() => resizeImage(imageToDisplay, 'black')} />
                        <ColorSelectIcon color="white" onClick={() => resizeImage(imageToDisplay, 'white')} />
                      </>
                    )}
                  </div>
                  <Button
                    color="red"
                    className={`rounded-md h-[2.5rem] ${editInProgress ? '' : 'opacity-50'}`}
                    disabled={!editInProgress}
                    onClick={() => confirmImageResize(imageToDisplay)}
                  >
                    Confirm
                  </Button>
                </div>
              </div>
            </div>
            <Icon
              className="cursor-pointer"
              name="close"
              onClick={() => {
                if (originalImage?.name) closeDimensionError(originalImage.name);
              }}
            />
          </div>
        )}
        <div className="flex flex-nowrap justify-between items-center w-full p-2">
          <div className="flex flex-nowrap items-center w-full">
            <img
              className="w-[200px] mr-[20px]"
              style={{ backgroundColor: brandColor }}
              alt={originalImage?.name}
              role="presentation"
              src={objectURL}
              width={100}
            />
            <span className="max-w-[250px] whitespace-nowrap overflow-hidden truncate">{originalImage?.name}</span>
          </div>
          <div className="flex flex-nowrap justify-start items-center">
            <div className="text-left">
              <Select
                required
                name="kind"
                options={imageKindOptions}
                defaultValue={imageKind}
                onChange={(e, { value }) => {
                  if (typeof value === 'string') {
                    if (isValidImageKind(value)) {
                      const newImageDetails = imageDetails.map(imageDetail => {
                        return imageDetail.originalImage.name === actualFile.name
                          ? { ...imageDetail, kind: value, brandColor: DEFAULT_BRAND_COLOR }
                          : { ...imageDetail };
                      });
                      setImageDetails(newImageDetails);
                    }
                  }
                }}
              />
              {isLogoTransparent(kind) && (
                <div className="flex flex-wrap w-full mt-4">
                  <label htmlFor="color" className="text-sm font-bold">
                    Background Color
                  </label>
                  <div className="flex flex-nowrap items-center">
                    <input className="mr-2 h-[45px]" type="color" value={brandColor} disabled />
                    <Input
                      type="text"
                      id="color"
                      className="w-[100px]"
                      value={brandColor?.startsWith('#') ? brandColor : `#${brandColor}`}
                      onChange={e => {
                        const value = e.target.value.startsWith('#') ? e.target.value : `#${e.target.value}`;
                        setImageDetails(
                          imageDetails.map(imageDetail => {
                            return imageDetail.originalImage.name === actualFile.name
                              ? {
                                  ...imageDetail,
                                  brandColor: value,
                                }
                              : imageDetail;
                          }),
                        );
                      }}
                    />
                  </div>
                </div>
              )}
              <div className="flex items-center justify-start w-full mt-2">
                <InputSwitch
                  checked={shouldAddMargin}
                  onChange={() => {
                    setShouldAddMargin(originalImage.name, !shouldAddMargin);
                  }}
                  inputId="add-margin"
                  pt={{
                    root: {
                      className: 'mt-2 relative inline-block w-[3rem] h-[1.75rem]',
                    },
                    input: {
                      className:
                        'appearance-none absolute top-0 left-0 w-full h-full p-0 m-0 opacity-0 z-10 outline-none cursor-pointer',
                    },
                    slider: {
                      className:
                        'absolute cursor-pointer top-0 left-0 right-0 bottom-0 border border-transparent before:content-[""] before:absolute before:top-1/2 bg-[#d1d5db] transition-all duration-200 !rounded-full outline-none before:bg-white before:w-[1.25rem] before:h-[1.25rem] before:left-[0.25rem] before:mt-[-0.625rem] before:rounded-full before:transition-200',
                    },
                  }}
                />
                <label htmlFor="add-margin" className="text-inherit cursor-pointer ml-[5px]">
                  Add margin
                </label>
              </div>
            </div>
            <Button
              className="!rounded-full !ml-[20px] !mr-[10px] !aspect-square"
              icon="close"
              onClick={() => removePreview(actualFile, () => options.onRemove({} as any))}
            />
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    validateImages();
  }, [imageDetails]);

  return (
    <Modal
      trigger={<Icon name="add" size="large" onClick={() => setModalOpen(true)} />}
      open={modalOpen}
      onClose={() => {
        setImageDetails([]);
        setDimensionErrors({});
        setGeneralErrors([]);
        setModalOpen(false);
        setIsLoading(false);
      }}
    >
      <Modal.Header>
        <Icon name="image" />
        Add Image
      </Modal.Header>
      <Modal.Content>
        <div className="form-add-image font-montserrat">
          {!!generalErrors.length &&
            generalErrors.map(error => (
              <div key={error} className="errorMessage" onClick={() => closeGeneralError(error)}>
                {error}
                <Icon className="close close-button" name="close" onClick={() => closeGeneralError(error)} />
              </div>
            ))}
          {isLoading ? (
            <Segment className="border-0 shadow-none mt-[50px]">
              <Dimmer active inverted>
                <Loader inverted content="Loading" />
              </Dimmer>
            </Segment>
          ) : (
            <>
              <FileUpload
                multiple
                customUpload
                name="merchant-admin-image-upload"
                accept="image/*"
                chooseLabel="Browse"
                url="api/uploader"
                maxFileSize={1024 * 1024 * MAX_SIZE}
                emptyTemplate={dropZone}
                itemTemplate={imageControls}
                onSelect={(event: FileUploadSelectEvent) => handleSelect(event)}
                onClear={() => setCanUpload(false)}
                uploadHandler={handleUpload}
                contentClassName="flex items-center border border-[rgba(34, 36, 38, 0.15)] rounded-md p-4 my-4"
                cancelOptions={{
                  className:
                    'bg-button-light-grey text-[rgba(0, 0, 0, 0.6)] px-[10px] py-[5px] rounded-md text-md font-bold disabled:opacity-50 hover:bg-[#cacbcd] gap-2  ',
                }}
                chooseOptions={{
                  className:
                    'bg-button-light-grey text-[rgba(0, 0, 0, 0.6)] px-[10px] py-[5px] rounded-md text-md font-bold disabled:opacity-50 hover:bg-[#cacbcd] mr-[5px] gap-2',
                }}
                pt={{
                  uploadButton: {
                    root: {
                      className: `bg-button-light-grey text-[rgba(0, 0, 0, 0.6)] px-[10px] py-[5px] rounded-md text-md font-bold hover:bg-[#cacbcd] mr-[5px] gap-2 ${canUpload ? '' : 'opacity-50'}`,
                      disabled: canUpload ? false : true,
                    },
                  },
                }}
              />
            </>
          )}
          <div className="p-[10px] bg-[#e0e1e2] mt-[20px] text-xs">
            <p className="mb-1">
              Featured images should be{' '}
              <span className="font-bold">
                {IMAGE_KINDS.FEATURED.WIDTH}px x {IMAGE_KINDS.FEATURED.HEIGHT}px
              </span>
              .
            </p>
            <p className="mb-1">
              Logo images should be{' '}
              <span className="font-bold">
                {IMAGE_KINDS.LOGO.WIDTH}px x {IMAGE_KINDS.LOGO.HEIGHT}px
              </span>
              .
            </p>
            <p className="mb-1">
              LogoRect images should be{' '}
              <span className="font-bold">
                {IMAGE_KINDS.LOGORECT.WIDTH}px x {IMAGE_KINDS.LOGORECT.HEIGHT}px
              </span>
              .
            </p>
            <p className="mb-1">
              LogoTransparent images should be{' '}
              <span className="font-bold">
                {IMAGE_KINDS.LOGOTRANSPARENT.WIDTH}px x {IMAGE_KINDS.LOGOTRANSPARENT.HEIGHT}px
              </span>
              .
            </p>
            <p>
              Max image size is <span className="font-bold">{MAX_SIZE}MB</span>.
            </p>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default AddImage;
