import React from 'react';
import { Modal, Table } from 'semantic-ui-react';
import { TxInquirySummaryByApp } from './types';

interface TxInquiryAppSummaryModalProps {
  modalOpen: boolean;
  setModalOpen: (modalOpen: boolean) => void;
  summaryByApp: TxInquirySummaryByApp[];
}

const TxInquiryAppSummaryModal: React.FC<TxInquiryAppSummaryModalProps> = ({
  modalOpen,
  setModalOpen,
  summaryByApp,
}) => {
  const tableRows = summaryByApp.map(app => (
    <Table.Row key={app.name} className="text-sm whitespace-nowrap">
      <Table.Cell>{app.name}</Table.Cell>
      <Table.Cell className="!text-center">{app.count}</Table.Cell>
      <Table.Cell className="font-dm-mono">{`$${parseFloat(app.total).toFixed(2)}`}</Table.Cell>
    </Table.Row>
  ));
  return (
    <div>
      <Modal
        onClose={() => setModalOpen(false)}
        onOpen={() => setModalOpen(true)}
        open={modalOpen}
        size="tiny"
        centered={true}
        closeIcon={true}
        className="min-w-[600px]"
      >
        <Modal.Content className="text-center w-full">
          <Table celled className="font-montserrat">
            <Table.Header>
              <Table.Row className="text-center font-montserrat">
                <Table.HeaderCell className="text-sm font-normal whitespace-nowrap">
                  Payable Application
                </Table.HeaderCell>
                <Table.HeaderCell className="text-sm font-normal whitespace-nowrap">
                  Bonus Payment Count
                </Table.HeaderCell>
                <Table.HeaderCell className="text-sm font-normal whitespace-nowrap">
                  Bonus Payment Total
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>{tableRows}</Table.Body>
          </Table>
        </Modal.Content>
      </Modal>
    </div>
  );
};

export default TxInquiryAppSummaryModal;
