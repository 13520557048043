import React from 'react';
import { connect } from 'react-redux';
import { Table } from 'semantic-ui-react';
import { getBonusPaymentsList } from 'actions/reconciles/reconciles';
import { TxInquiry } from './types';
import TxInquiryTableDownload from './TxInquiryTableDownload';

interface TxInquiryTableProps {
  txInquiryList: TxInquiry[];
}

const tableColumns = [
  { name: 'Network', key: 'network' },
  { name: 'Merchant', key: 'merchant' },
  { name: 'Payable Application', key: 'payableApplication' },
  { name: 'Commission ID', key: 'commissionId' },
  { name: 'Created Date', key: 'createdDate' },
  { name: 'Created By', key: 'createdBy' },
  { name: 'Sale Amount', key: 'saleAmount' },
  { name: 'Commission Amount', key: 'commissionAmount' },
  { name: 'Status', key: 'status' },
  { name: 'Last Updated', key: 'lastUpdated' },
  { name: 'USD Payout', key: 'usdPayout' },
];

const TxInquiryTable: React.FC<TxInquiryTableProps> = ({ txInquiryList }) => {
  const getCommissionID = (txInquiry: TxInquiry) => {
    const { bonusCommissionId, originalCommissionId } = txInquiry;
    if (bonusCommissionId === 0 && originalCommissionId === 0) {
      return 0;
    } else {
      return bonusCommissionId || originalCommissionId;
    }
  };

  const tableRows = txInquiryList.map(txInquiry => (
    <Table.Row key={txInquiry.id} className="text-sm whitespace-nowrap">
      <Table.Cell>{txInquiry.networkName}</Table.Cell>
      <Table.Cell>{txInquiry.networkMerchantName}</Table.Cell>
      <Table.Cell className="truncate max-w-[200px]">{`${txInquiry.payableApplicationId} - ${txInquiry.payableApplicationName}`}</Table.Cell>
      <Table.Cell className="!text-center">{getCommissionID(txInquiry)}</Table.Cell>
      <Table.Cell>{txInquiry.createdDate.split('T')[0]}</Table.Cell>
      <Table.Cell>{txInquiry.modifiedAuthor}</Table.Cell>
      <Table.Cell className="!text-right font-dm-mono">{`${txInquiry.saleAmount} ${txInquiry.saleCurrencyCode}`}</Table.Cell>
      <Table.Cell className="!text-right font-dm-mono">{`${txInquiry.commissionAmount} ${txInquiry.applicationCurrencyCode}`}</Table.Cell>
      <Table.Cell>{txInquiry.status}</Table.Cell>
      <Table.Cell>{txInquiry.modifiedDate.split('T')[0]}</Table.Cell>
      <Table.Cell className="!text-right font-dm-mono">{`${parseFloat(txInquiry.usdBonusAmount).toFixed(2)} USD`}</Table.Cell>
    </Table.Row>
  ));

  return (
    <>
      <div className="mb-4">
        <TxInquiryTableDownload tableData={txInquiryList} getCommissionID={getCommissionID} />
      </div>
      <div className="font-montserrat overflow-auto mx-auto w-full">
        <Table celled>
          <Table.Header className="xs:!hidden sm:!table-header-group">
            <Table.Row className="text-center">
              {tableColumns.map(column => (
                <Table.HeaderCell key={column.key} className="text-sm font-normal whitespace-nowrap">
                  {column.name}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Body>{tableRows}</Table.Body>
        </Table>
      </div>
    </>
  );
};

const mapActionsToProp = {
  getBonusPaymentsList,
};

export default connect(null, mapActionsToProp)(TxInquiryTable);
